import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import logoIconGreen from '../../../assets/svg/logoVerde.svg'
import Header from '../../../components/header/index';
import Footer from '../../../components/footer/index'
import GoogleFontLoader from 'react-google-font-loader';
import { Row, Col } from 'react-bootstrap';


export default function GetInTouchSection() {

    return (
        <div>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />
            <Header></Header>
            <div className="container-fluid margin22">
                <Col className='w-100'>
                    <Row>
                        <div className='textGetInTouch'>
                            <img
                                src={logoIconGreen}
                                alt="Em breve"
                                className="App-logo rotateIcon"
                                width={120}
                            />
                        </div>
                        <p className='justify-content-center h2 align-items-center textGetInTouch' style={{ fontFamily: 'Georama' }}>
                            Vamos tornar o mundo mais elétrico <b>juntos?</b>
                            <br></br>
                            <br></br>
                        </p>
                        <p className='justify-content-center align-items-center textGetInTouch' style={{ fontFamily: 'Georama' }}>
                            <br></br>
                            Entre em contato.
                            <p></p>
                            <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a>
                        </p>
                    </Row>
                </Col>
            </div>
            <div className='altura-30 bg-black1'></div>
            <div className='altura-30 bg-black1'></div>
            <div className='altura-30 bg-black1'></div>
            <div className='altura-30 bg-black1'></div>
            <div className='altura-30 bg-black1'></div>
            <div className='altura-30 bg-black1'></div>


            <Footer></Footer>
        </div >
    );
}
