import React from 'react';
import "./styles.css"
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import SustainableIcon from '../../../assets/svg/blackAndWhite/planet-earth.svg';
import QualityIcon from '../../../assets/svg/blackAndWhite/reliability.svg';
import InovationIcon from '../../../assets/svg/blackAndWhite/project-management.svg';
import HealthIcon from '../../../assets/svg/blackAndWhite/haltere.svg';
import GoogleFontLoader from 'react-google-font-loader';




export default function Values() {

    return (

        <Container fluid className='valuesContainer'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] }
                ]}
            />
            <Container>
            <h5 className='containerValuesTitle' style={{ fontFamily: 'Cairo Play' }}>NOSSOS VALORES</h5><br></br>
               <Col className='colValuesAlign' >
                <Row md="auto">
                    <Card className='cardBorder ' style={{ width: "230px" }}>
                        <Card.Img className='mx-auto' variant="top" src={SustainableIcon} style={{ width: '5rem' }} />
                        <Card.Body >
                            <Card.Title className='text-center valuesTextColorSUS' style={{ fontFamily: 'Cairo Play' }}>SUSTENTABILIDADE</Card.Title>
                        </Card.Body>
                    </Card>


                    <Card className='cardBorder' style={{ width: "230px" }}>
                        <Card.Img className='mx-auto' variant="top" src={InovationIcon} style={{ width: '5rem' }} />
                        <Card.Body>
                            <Card.Title className='text-center valuesTextColorINV' style={{ fontFamily: 'Cairo Play' }}>INOVAÇÃO</Card.Title>
                        </Card.Body>
                    </Card>

                    <Card className='cardBorder' style={{ width: '230px' }}>
                        <Card.Img className='mx-auto' variant="top" src={QualityIcon} style={{ width: '5rem' }} />
                        <Card.Body>
                            <Card.Title className='text-center valuesTextColorQUA' style={{ fontFamily: 'Cairo Play' }}>QUALIDADE</Card.Title>
                        </Card.Body>
                    </Card>

                    <Card className='cardBorder' style={{ width: '230px' }}>
                        <Card.Img className='mx-auto' variant="top" src={HealthIcon} style={{ width: '5rem' }} />
                        <Card.Body >
                            <Card.Title className='text-center valuesTextColorSAU' style={{ fontFamily: 'Cairo Play' }}>SAÚDE</Card.Title>
                        </Card.Body>
                    </Card>
                </Row>
                </Col>
            </Container>
            <div className='spaceBar2'></div>
        </Container>
    )
}