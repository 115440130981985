import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/header';
import Footer from '../../components/footer';
import BuyYours from './buyYours';
import './styles.css'
import FaqSection from './faqSection/faqSection';
import AdvantageSection from '../home/advantagesSection/index';
import Video from '../home/entranceVideo/index'
import RegisterForYours from './registerForYours';



const PageHome = () => {
    return (
        <div>
            <div className=''>
                <Header></Header>
                <div className='spaceBar'></div>
                <Video></Video>
                <AdvantageSection></AdvantageSection>
                <BuyYours></BuyYours>
                <RegisterForYours></RegisterForYours>
                <FaqSection></FaqSection>
                <Footer></Footer>
            </div>
        </div>
    )
};


export default PageHome