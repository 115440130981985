import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import FormGetOffer from '../getOffer/form/index';




const PageGetOffer = () => {

    return (
        <div className=''>
            <FormGetOffer></FormGetOffer>
        </div>
    )
};

export default PageGetOffer