import React from 'react';
import "./styles.css"
import { Container, Col } from 'react-bootstrap';
import GoogleFontLoader from 'react-google-font-loader';


import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


export default function TimelineSection() {

    return (


        <Container fluid className='bg-gray timelineContainer'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />
            <Col>
                <h3 className='ourVisionTitle' style={{ fontFamily: 'Cairo Play' }}>NOSSA HISTÓRIA</h3><br></br>

                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        /*                       contentStyle={{ background: 'rgb(50, 162, 131)', color: '#101010' }} */
                        /*                         contentArrowStyle={{ borderRight: '7px solid  rgb(50, 162, 131)' }} */
                        date="2018-2020"
                        key={1}
                        iconStyle={{ background: 'rgb(50, 162, 131)', color: '#fff' }}

                    >
                        <h4 className="vertical-timeline-element-title timelineElementTitle" style={{ fontFamily: 'Georama' }}>Concepção da ideia</h4>

                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020"
                        key={2}
                        iconStyle={{ background: 'rgb(50, 162, 131)', color: '#fff' }}
                    >
                        <h4 className="vertical-timeline-element-title timelineElementTitle">Protótipo Inaugural</h4>

                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2021"
                        key={3}
                        iconStyle={{ background: 'rgb(50, 162, 131)', color: '#fff' }}
                    >
                        <h4 className="vertical-timeline-element-title timelineElementTitle">Evolução Tecnológica</h4>

                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2022"
                        key={4}
                        iconStyle={{ background: 'rgb(50, 162, 131)', color: '#fff' }}
                    >
                        <h4 className="vertical-timeline-element-title timelineElementTitle">Interface Amigável e Comunicação</h4>

                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2023"
                        key={5}
                        iconStyle={{ background: 'rgb(50, 162, 131)', color: '#fff' }}
                    >
                        <h4 className="vertical-timeline-element-title timelineElementTitle">Produto Minimamente Viável (MVP)</h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2024"
                        key={6}
                        iconStyle={{ background: 'rgb(11, 157, 161)' }}
                    >
                        <h4 className="vertical-timeline-element-title timelineElementNewTitle">Transformação e Lançamento</h4>
                    </VerticalTimelineElement>
                    <div className='spaceBar'></div>
                </VerticalTimeline>
            </Col>
        </Container>
    )
}