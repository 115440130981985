import React, { useState, useEffect } from 'react';
import VideoEntrance1920vs1080 from "../../../assets/mov/VideoEntrance1920vs1080.mp4"
import VideoEntrance1280vs720 from "../../../assets/mov/VideoEntrance1280vs720.mp4"
import VideoEntrance768 from "../../../assets/mov/VideoEntrance768.mp4"
import { useMediaQuery } from 'react-responsive';
import { Spinner } from 'react-bootstrap';

export default function Video() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isHD = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1280px)' });
  const isFullHD = useMediaQuery({ query: '(min-width: 1281px)' });


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500); // Define um atraso de 2,5 segundos antes de remover o spinner

    return () => clearTimeout(timer); // Limpa o timeout quando o componente é desmontado
  }, []); // O efeito só será executado uma vez após a montagem do componente


  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden'; // Desativa o scroll do body
    } else {
      document.body.style.overflow = ''; // Reativa o scroll do body
    }
  }, [isLoading]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {isLoading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(10, 10, 10, 0.8)', // Define um fundo semi-transparente
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Spinner animation="border" variant="light" />
        </div>
      )}
      {isMobile && (
        <video width="100%" height="auto" muted loop autoPlay playsInline>
          <source muted autoPlay loop src={VideoEntrance768} type="video/mp4" />
          <source muted autoPlay loop src={VideoEntrance768} type="video/webm" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      )}
      {isHD && (
        <video width="100%" height="auto" muted loop autoPlay playsInline>
          <source muted autoPlay loop src={VideoEntrance1280vs720} type="video/mp4" />
          <source muted autoPlay loop src={VideoEntrance1280vs720} type="video/webm" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      )}
      {isFullHD && (
        <video width="100%" height="auto" muted loop autoPlay playsInline>
          <source muted autoPlay loop src={VideoEntrance1920vs1080} type="video/mp4" />
          <source muted autoPlay loop src={VideoEntrance1920vs1080} type="video/webm" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      )}
    </div>
  );
}
