import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Mission from './mvv/mission';
import Vision from './mvv/vision';
import Values from './mvv/values';
import TimelineSection from './timelineSection';
import BuyYoursBlue from './buyYoursBlue';




const PageAbout = () => {

    return (
        <div>

            <Header></Header>
            <div className='spaceBar'></div>
            <Mission></Mission>
            <Vision></Vision>
            <Values></Values>
            <TimelineSection></TimelineSection>
            <BuyYoursBlue></BuyYoursBlue>
            <Footer></Footer>

        </div>
    )
};

export default PageAbout