import React from 'react'
import './styles.css'
/* import Button from 'react-bootstrap/Button'; */
import Card from 'react-bootstrap/Card';
import regenerativeBrakingIcon from '../../../assets/svg/regenerativeBraking.svg'
//import intelectualPropertyIcon from '../../../assets/svg/intelectualProperty.svg'
/* import GPSIcon from '../../../assets/svg/GPS.svg' */
import communicationIcon from '../../../assets/svg/Communication.svg'
import { Container, Row } from 'react-bootstrap';
import brazilianTechnology from '../../../assets/svg/brazilianTechnology.svg';
import GoogleFontLoader from 'react-google-font-loader';


function AdvantageSection() {

    return (
        <div className='advantageSectionDIV'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />
            <Container fluid className='marginContainer'>
                <Row md="auto">
                    {/*
                    <div className="col-sm-4 cardDiv">
                        <Card className='cardClass cardBorder' style={{ width: '20rem' }}>
                            <Card.Img className='mx-auto' style={{ width: '16rem' }} variant="top" src={brazilianTechnology} />
                            <Card.Body>
                                <Card.Title>Tecnologia brasileira</Card.Title>
                                <Card.Text className='textAlignCard'>
                                    Desbravando trilhas inexploradas da mobilidade, nossa empresa, com tecnologia <b>100% brasileira</b>, transforma o ordinário em extraordinário. Bicicletas elétricas e kits conversores: uma revolução silenciosa, nascida em terras tropicais. Desperte seu instinto de explorador, venha ser parte do futuro.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div> */}
                    <div className="col-sm-4 cardDiv d-flex justify-content-center">
                        <Card className='cardClass cardBorder' style={{ width: '22rem' }}>
                            <Card.Img className='mx-auto' style={{ width: '14rem' }} variant="top" src={brazilianTechnology} />
                            <Card.Body>
                                <Card.Title className='text-center' style={{ fontFamily: 'Cairo Play' }}>TECNOLOGIA BRASILEIRA</Card.Title>
                                <Card.Text className='textAlignCard text-center' style={{ fontFamily: 'Georama' }}>
                                    Desbravando trilhas inexploradas da mobilidade, somos desenvolvedores de toda a tecnologia do produto.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-sm-4 cardDiv d-flex justify-content-center">
                        <Card className='cardClass cardBorder' style={{ width: '22rem' }}>
                            <Card.Img className='mx-auto' style={{ width: '14rem' }} variant="top" src={regenerativeBrakingIcon} />
                            <Card.Body>
                                <Card.Title className='text-center' style={{ fontFamily: 'Cairo Play' }}>FRENAGEM REGENERATIVA</Card.Title>
                                <Card.Text className='text-center' style={{ fontFamily: 'Georama' }}>
                                    A cada frenagem um novo impulso para frente, elevando a eficiência e abrindo uma nova era sustentável.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-sm-4 cardDiv d-flex justify-content-center">
                        <Card className='cardClass cardBorder' style={{ width: '22rem' }}>
                            <Card.Img className='mx-auto' style={{ width: '14rem' }} variant="top" src={communicationIcon} />
                            <Card.Body>
                                <Card.Title className='text-center' style={{ fontFamily: 'Cairo Play' }}>CONECTIVIDADE</Card.Title>
                                <Card.Text className='text-center' style={{ fontFamily: 'Georama' }}>
                                    Em sintonia com a inovação, nossos produtos oferecem uma experiência única em termos de conectividade.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    {/*                     <div className="col-sm-4 cardDiv">
                        <Card className='cardClass cardBorder' style={{ width: '20rem' }}>
                            <Card.Img className='mx-auto' style={{ width: '16rem' }} variant="top" src={intelectualPropertyIcon} />
                            <Card.Body>
                                <Card.Title>Propriedade intelectual</Card.Title>
                                <Card.Text>
                                Ter a propriedade intelectual de nossos produtos é garantia de inovação exclusiva. Acreditamos fervorosamente que esta chave para a originalidade não apenas inspira confiança, mas também nos impulsiona a continuar elevando os padrões, oferecendo soluções únicas e marcando nosso compromisso com o futuro.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div> */}
                </Row>
            </Container>

        </div>
    )
}

export default AdvantageSection