import React from 'react';
import "./styles.css"
import { Container } from 'react-bootstrap';
import GoogleFontLoader from 'react-google-font-loader';




export default function Mission() {

    return (

        <Container fluid className='missionContainer'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i']}
                ]}
            />
            <div className='spaceBar1'> </div>
            <Container>
                <h5 className='containerMissionTitle' style={{ fontFamily: 'Cairo Play' }}> NOSSA MISSÃO </h5><br></br>
                <h1 className='align-items-center missionTextColor' style={{ fontFamily: 'Georama'}} >
                Nosso objetivo é revolucionar a mobilidade urbana através de um modo sustentável, eficiente e acessível de transporte: <b>Bicicletas Elétricas.</b>
                </h1>
            </Container>
        </Container>
    )
}