import React from 'react'
import './styles.css'
//import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Container, Row, Col } from 'react-bootstrap';
import GoogleFontLoader from 'react-google-font-loader';
import instagramIcon from '../../../assets/png/instagram_2111463.png'
import facebookIcon from '../../../assets/png/facebook.png'
import youtubeIcon from '../../../assets/png/youtubeColor.png'
import { Tooltip } from 'react-tooltip'


function BuyYoursBlue() {

    return (
        <div className='buyYoursDIVBlue'>

            <Container fluid>
                <GoogleFontLoader
                    fonts={[
                        { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                        { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                    ]}
                />
                <Col >
                    <Row className='colAlignCenter'>

                        <h2 style={{ fontFamily: 'Georama' }}>As vendas iniciam em breve...</h2><br></br>
                        <h6 style={{ fontFamily: 'Georama' }}>Fique de olhos em nossas redes sociais!</h6>
                    </Row>
                    <Row className=''>
                        <div className=" container d-flex align-items-end justify-content-center ">
                            <a href='https://www.instagram.com/primax.ebikes/'
                                data-tooltip-id="instagram-tooltip"
                                data-tooltip-content="Acesso nosso Instagram!"
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={10}>
                                <img
                                    src={instagramIcon}
                                    width={25}
                                    alt="Logotipo instagram"
                                    className="socialMediaSpace"
                                />
                                <Tooltip id="instagram-tooltip" className="tooltip" />
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=61551105877417'
                                data-tooltip-id="facebook-tooltip"
                                data-tooltip-content="Acesso nossa página no Facebook!"
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={10}>
                                <img
                                    src={facebookIcon}
                                    width={25}
                                    alt="Logotipo facebook"
                                    className="socialMediaSpace" />
                                <Tooltip id="facebook-tooltip" className="tooltip" />
                            </a>
                            <a href='https://www.youtube.com/@primaxebikes'
                                data-tooltip-id="youtube-tooltip"
                                data-tooltip-content="Curta nosso conteúdo no Youtube!"
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={10}>
                                <img
                                    src={youtubeIcon}
                                    width={27}
                                    alt="Logotipo youtube"
                                    className="socialMediaSpace" />
                                <Tooltip id="youtube-tooltip" className="tooltip" />
                            </a>
                        </div>

                    </Row>
                    <Row className='colAlignCenter'>
                        <ProgressBar className='progressBarStyle orange-progress-bar p-0' animated now={85} />

                    </Row>

                    {/*                     <div className='colAlignCenter mt-5'>
                        <Button className='buttonCenter' href="/about" size="lg" variant="outline-secondary ">Sobre</Button>{' '}
                        <h5>Conheça nossa história</h5>
                    </div> */}
                </Col>

            </Container >






        </div>
    )
}

export default BuyYoursBlue