import React, { useState } from 'react';
import './styles.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import GoogleFontLoader from 'react-google-font-loader';
import { useMediaQuery } from 'react-responsive';
import lockerLocked from "../../../assets/svg/lockerLocked.svg"

function RegisterForYours() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [submittedScreen, setSubmittedScreen] = useState(true)

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };


    function scrollToCenteredDiv() {

        var div1 = document.getElementById("div1")
        var offSetTop = div1.offsetTop
        var elementHeight = div1.offsetHeight
        var windowHeight = window.innerHeight
        var scrollTo = offSetTop - (windowHeight - elementHeight) / 2

        window.scrollTo({
            top: scrollTo,
            behavior: 'smooth'
        })

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Nome:', name);
        console.log('Email:', email);
        setSubmittedScreen(false);
        scrollToCenteredDiv()


        //Aqui

        var jsonAPI = JSON.stringify({ name, email })

        fetch('https://subscriptiondb.azurewebsites.net/api/subscriptionform/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: jsonAPI
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao enviar os dados do formulário.');
                }
                return response.text();
            })

            .then(data => {
                // Imprimindo o corpo da resposta
                console.log(data);
                // Você pode fazer o que quiser com os dados aqui
            })

            .catch(error => {
                console.error('Caiu nesse erro aqui:', error);
            });
    };

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isHD = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1280px)' });
    const isFullHD = useMediaQuery({ query: '(min-width: 1281px)' });


    return (

        <div className='registerForYoursDIV' id='div1'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />

            {submittedScreen ? (

                <Container fluid>
                    <Col >
                        <Row className='colAlignCenter'>
                            {isMobile && (
                                <div>
                                    <h1 className='blackFont' style={{ fontSize: '48px', fontFamily: 'Georama' }}>Fique atualizado.</h1><br></br>
                                </div>
                            )}
                            {isHD && (
                                <div>
                                    <h1 className='blackFont' style={{ fontSize: '72px', fontFamily: 'Georama' }}>Fique atualizado.</h1><br></br>
                                </div>
                            )}
                            {isFullHD && (
                                <div>
                                    <h1 className='blackFont' style={{ fontSize: '80px', fontFamily: 'Georama' }}>Fique atualizado.</h1><br></br>
                                </div>
                            )}
                        </Row>
                        <Row className='colAlignCenter'>
                            <h6 className='blackFont' style={{ fontFamily: 'Georama' }}>Cadastre-se e esteja sempre atualizado sobre nós e nossos produtos.</h6>
                            <br></br>
                        </Row>
                        <Row className='colAlignCenter'>
                            <br></br>
                            <Form className='forms' onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Control className='formsInside' type="name" placeholder="Seu nome" value={name} onChange={handleNameChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control className='formsInside' type="email" placeholder="seuemail@example.com" value={email} onChange={handleEmailChange} />
                                </Form.Group>
                                <Button className='sendButton' type="submit" style={{ fontFamily: 'Georama' }}>ENVIAR</Button>{' '}
                            </Form>
                        </Row>
                        <br></br>
                    </Col>
                </Container>

            ) : (
                <Container fluid>
                    <Col >
                        <Row className='colAlignCenter'>
                            {isMobile && (
                                <div>
                                    <h1 className='blackFont' style={{ fontSize: '48px', fontFamily: 'Georama' }}>Guardamos seus dados, {name}.</h1>
                                    <div className="lockerLockedDIV">
                                        <img
                                            src={lockerLocked}
                                            alt="Locker"
                                            className="lockerLocked1" />
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <h4 style={{ fontFamily: 'Georama' }}>Manteremos você informado sobre quaisquer novidades!</h4>
                                </div>
                            )}
                            {isHD && (
                                <div>
                                    <h1 className='blackFont' style={{ fontSize: '72px', fontFamily: 'Georama' }}>Guardamos seus dados, {name}.</h1>
                                    <div className="lockerLockedDIV">
                                        <img
                                            src={lockerLocked}
                                            alt="Locker"
                                            className="lockerLocked2"
                                        />
                                    </div>
                                    <br></br>
                                    <h4 style={{ fontFamily: 'Georama' }}>Manteremos você informado sobre quaisquer novidades!</h4>
                                </div>
                            )}
                            {isFullHD && (
                                <div>
                                    <Row >
                                        <h1 className='blackFont' style={{ fontSize: '80px', fontFamily: 'Georama' }}>Guardamos seus dados, {name}.</h1>
                                        <div className="lockerLockedDIV">
                                            <img
                                                src={lockerLocked}
                                                alt="Locker"
                                                className="lockerLocked3"
                                            />
                                        </div>
                                    </Row>
                                    <br></br>
                                    <h4 style={{ fontFamily: 'Georama' }}>Manteremos você informado sobre quaisquer novidades!</h4>
                                </div>
                            )}
                        </Row>
                        <br></br>
                    </Col>
                </Container>
            )}
        </div>
    );
}

export default RegisterForYours;
