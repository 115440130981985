import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import PageHome from './pages/home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PageAbout from './pages/about';
import PageContact from './pages/contact';
import PageGetOffer from './pages/getOffer';
import PageTerms from './pages/offerTerms';


const router = createBrowserRouter([

  {
      path: "/",
      errorElement: <PageHome />,
      children: [
          {
              path: "/home",
              element: <PageHome />
          },
          {
              path: "/",
              element: <PageHome />
          },
          {
              path: "/about",
              element: <PageAbout />
          },
          {
              path: "/contact",
              element: <PageContact />
          },
          {
              path: "/getOffer",
              element: <PageGetOffer />
          },
          {
              path: "/offerTerms",
              element: <PageTerms />
          }
      ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
