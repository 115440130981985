import React from 'react';
import "./styles.css"
import { Container } from 'react-bootstrap';
import GoogleFontLoader from 'react-google-font-loader';




export default function Vision() {

    return (

        <Container fluid className='visionContainer'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i']}
                ]}
            />
            <Container>
                <h5 className='containerVisionTitle' style={{ fontFamily: 'Cairo Play' }}> NOSSA VISÃO</h5><br></br>
                <h3 className='align-items-center visionTextColor' style={{ fontFamily: 'Georama'}} >
                Ser líder global na indústria de bicicletas elétricas, impulsionando a transição para um mundo mais verde e saudável. Queremos ser reconhecidos por nossos produtos de alta qualidade, sustentáveis e acessíveis, bem como por nosso impacto positivo nas comunidades onde operamos.
                </h3>
            </Container>
        </Container>
    )
}