import React from "react";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import fullLogoWhite from "../../assets/svg/fullLogoWhite.svg"
import './styles.css'
import GoogleFontLoader from 'react-google-font-loader';
import instagramIconBW from '../../assets/png/instagram_bw.png'
import facebookIconBW from '../../assets/png/facebookbw.png'
import emailIconBW from '../../assets/png/emailbw.png'
import youtubeIcon from '../../assets/png/youtube.png'
import linkedinIcon2 from '../../assets/png/linkedin2.png'
/* import tiktokIcon1 from '../../assets/png/tiktok1.png' */
import { Tooltip } from 'react-tooltip'

// Atenção: Não esquecer de alterar também no arquivo headerHome, que é utilizado
// na página Home

export default function Footer() {


    return (
        <footer className="primaxGreenColor">

            <div className="container primaxGreenColor" >
                <br />
            </div>
            <div className="container ">
                <div className="row logo-footer">
                    <div class="col-6 col-sm-4">
                        <Link to={"/home"}>
                            <img className
                                src={fullLogoWhite}
                                alt="Logotipo"
                                width={200} />
                        </Link>
                    </div>
                </div>
                <div className="container ms-0 ps-0">
                    <GoogleFontLoader
                        fonts={[
                            { font: 'Georama', weights: [400, '400i', 700, '700i'] },
                        ]}
                    />
                    <div className="row address">
                        <div class="col-6 col-sm-4 pe-0 " style={{ fontFamily: 'Georama' }}>
                            <p>Rua M, Nº 150, Bairro Jardins dos Inconfidentes<br></br> Mariana - MG | CEP 35.422-395 </p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-4 ms-0">
                    <div class='socialMedia'>
                        <a href='https://www.instagram.com/primax.ebikes/'
                            data-tooltip-id="instagram-tooltip"
                            data-tooltip-content="Acesso nosso Instagram!"
                            data-tooltip-place="top-start"
                            data-tooltip-offset={10}>
                            <img
                                src={instagramIconBW}
                                width={25}
                                alt="Logotipo instagram"
                                className="socialMediaSpace" />
                            <Tooltip id="instagram-tooltip" />
                        </a>

                        <a href='https://www.facebook.com/profile.php?id=61551105877417'
                            data-tooltip-id="facebook-tooltip"
                            data-tooltip-content="Acesso nossa página no Facebook!"
                            data-tooltip-place="top-start"
                            data-tooltip-offset={10}>
                            <img
                                src={facebookIconBW}
                                width={25}
                                alt="Logotipo facebook"
                                className="socialMediaSpace" />
                            <Tooltip id="facebook-tooltip" />
                        </a>
                        <a href='https://www.youtube.com/@primaxebikes'
                            data-tooltip-id="youtube-tooltip"
                            data-tooltip-content="Curta nosso conteúdo no Youtube!"
                            data-tooltip-place="top-start"
                            data-tooltip-offset={10}>
                            <img
                                src={youtubeIcon}
                                width={27}
                                alt="Logotipo youtube"
                                className="socialMediaSpace" />
                            <Tooltip id="youtube-tooltip" />
                        </a>
                        <a href="https://www.linkedin.com/company/primax-ebikes"
                            data-tooltip-id="linkedin-tooltip"
                            data-tooltip-content="Acesso nossa página no LinkedIn!"
                            data-tooltip-place="top-start"
                            data-tooltip-offset={10}>
                            <img
                                src={linkedinIcon2}
                                width={26}
                                alt="Logotipo linkedin"
                                className="socialMediaSpace" />
                            <Tooltip id="linkedin-tooltip" />
                        </a>
{/*                         <a href="https://www.tiktok.com/@primax.ebikes?_t=8ilPI5ZuuHt&_r=1"
                            data-tooltip-id="tiktok-tooltip"
                            data-tooltip-content="Acesso nosso TikTok!"
                            data-tooltip-place="top-start"
                            data-tooltip-offset={10}>
                            <img
                                src={tiktokIcon1}
                                width={27}
                                alt="Logotipo tiktok"
                                className="socialMediaSpace" />
                            <Tooltip id="tiktok-tooltip" />
                        </a> */}
                        <a href="mailto:contato@primaxebikes.com"
                            data-tooltip-id="email-tooltip"
                            data-tooltip-content="Entre em contato conosco por e-mail!"
                            data-tooltip-place="top-start"
                            data-tooltip-offset={10}>
                            <img
                                src={emailIconBW}
                                width={28}
                                alt="Logotipo email"
                                className="socialMediaSpace" />
                            <Tooltip id="email-tooltip" />
                        </a>

                    </div>
                </div>
                <div className="row ">
                    <hr></hr>
                    <div className='col-6 col-sm-12 ms-0 copyright' style={{ fontFamily: 'Georama' }}>
                        <div>
                            <p>© Copyright Primax eBikes 2024 </p>
                        </div>
                    </div>
                </div>
            </div>

        </footer >
    );
}
