import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import './styles.css';
import { Container, Button } from 'react-bootstrap';
import GoogleFontLoader from 'react-google-font-loader';

function ContextAwareToggle({ children, eventKey, callback }) {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <button
            type="button"
            style={{
                font: 'rgba(255, 192, 80, 1)',
                border: 'none',
                backgroundColor: '#f8f8f8',
            }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

export default function FaqSection() {
    const [activeKey, setActiveKey] = useState(null);

    const handleTogglePanel = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    return (
        <div className='faqSectionDIV'>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />
            <div className='row faqMarginContainer'>
                <div className='titleFaq'>
                    <h2 style={{ fontFamily: 'Georama' }}>Perguntas Frequentes</h2>
                </div>

                <Container
                    fluid
                    style={{
                        height: 'auto', // Ajustado para 'auto' por padrão
                        overflow: 'hidden',
                        transition: 'height 0.3s ease',
                    }}
                >
                    <Accordion
                        alwaysOpen activeKey={activeKey} onSelect={handleTogglePanel}>
                        {/*                         <Card>
                            <Card.Header as="h5">
                                <ContextAwareToggle eventKey="0">Por que investir em bicicletas elétricas?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>Investir em bicicletas elétricas é promover uma alternativa sustentável de transporte. Além de proporcionar ganho significativo ao meio ambiente, o mercado de mobilidade elétrica está em crescimento, oferecendo oportunidades lucrativas.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br> */}
                        <Card >
                            <Card.Header as="h5" style={{ fontFamily: 'Georama' }}>
                                <ContextAwareToggle eventKey="1" >Quais são os benefícios das bicicletas elétricas?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body style={{ fontFamily: 'Georama' }}>As bicicletas elétricas proporcionam uma opção de transporte eficiente, livre de emissões, que contribui para a redução do trânsito, melhora a saúde e promove um estilo de vida ativo.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br>
                        {/*                         <Card>
                            <Card.Header as="h5" >
                                <ContextAwareToggle eventKey="2">Os kits de conversão são compatíveis com todos os tipos de bicicletas?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>Sim, nossos kits de conversão foram projetados para serem compatíveis com a maioria dos tipos de bicicletas, incluindo bicicletas de montanha, urbanas e de estrada, oferecendo versatilidade para os ciclistas.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br> */}
                        <Card>
                            <Card.Header as="h5" style={{ fontFamily: 'Georama' }}>
                                <ContextAwareToggle eventKey="3">A bicicleta elétrica é resistente a condições climáticas adversas?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body style={{ fontFamily: 'Georama' }}>Sim, nossas bicicletas elétricas são projetadas para resistir a diversas condições climáticas, garantindo a durabilidade e desempenho mesmo em situações adversas.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br>
                        <Card>
                            <Card.Header as="h5" style={{ fontFamily: 'Georama' }}>
                                <ContextAwareToggle eventKey="4">Qual é a autonomia média das bicicletas elétricas da Primax?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body style={{ fontFamily: 'Georama' }}>Nossas bicicletas elétricas oferecem uma autonomia média de 40 km com uma única carga, proporcionando conveniência e eficiência para os usuários.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br>
                        {/*                         <Card>
                            <Card.Header as="h5">
                                <ContextAwareToggle eventKey="5">Existem incentivos fiscais ou subsídios disponíveis para investidores interessados?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>Sim, atualmente existem incentivos fiscais e subsídios para empresas que investem em mobilidade elétrica. Nossa equipe está disponível para fornecer informações detalhadas sobre essas oportunidades.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br> */}
                        <Card>
                            <Card.Header as="h5" style={{ fontFamily: 'Georama' }}>
                                <ContextAwareToggle eventKey="6">A bicicleta elétrica é adequada para todas as idades?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body style={{ fontFamily: 'Georama' }}>Sim, nossa bicicleta elétrica é projetada para atender a diversas faixas etárias, proporcionando uma solução de transporte acessível e eficiente para pessoas de todas as idades.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br>
                        <Card>
                            <Card.Header as="h5" style={{ fontFamily: 'Georama' }}>
                                <ContextAwareToggle eventKey="7">Como posso adquirir a minha?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body style={{ fontFamily: 'Georama' }}>Caso você tenha interesse em adquirir sua e-bike Primax, fique atento às nossas redes sociais. As vendas começarão em breve! </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br></br>
                        <Card>
                            <Card.Header as="h5" style={{ fontFamily: 'Georama' }}>
                                <ContextAwareToggle eventKey="8">Como posso fazer parte dessa história?</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="8">
                                <div>
                                    <Card.Body style={{ fontFamily: 'Georama' }}>Caso você tenha interesse em nos ajudar a construir essa história, entre em contato pelo e-mail <a href="mailto:contato@primaxebikes.com">contato@primaxebikes.com</a>.</Card.Body>
                                    <div className='colAlignCenter'>
                                        <Button className='aboutButton1' href="/about" style={{ fontFamily: 'Georama' }}>SOBRE NÓS</Button>{' '}
                                    </div>
                                </div>
                            </Accordion.Collapse>

                        </Card>
                    </Accordion>
                </Container>


            </div>
        </div>
    );
}
