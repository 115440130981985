import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import GetInTouchSection from './getInTouchSection/getInTouchSection';





const PageContact = () => {

    return (
        <div className=''>
            <GetInTouchSection></GetInTouchSection>
        </div>
    )
};

export default PageContact