import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import logoIconGreen from '../../../assets/svg/logoVerde.svg'
import GoogleFontLoader from 'react-google-font-loader';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useMediaQuery } from 'react-responsive';
import allDoneIcon from '../../../assets/json/allDone.json';
import forward from '../../../assets/json/forward.json';
import ticket from '../../../assets/json/ticket1.json';
import Lottie from 'lottie-react';
import { v4 as uuidv4 } from 'uuid';
import copyIcon from '../../../../src/assets/svg/blackAndWhite/copy.svg';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import fullLogoWhite from "../../../assets/svg/fullLogoWhite.svg"
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';



export default function FormGetOffer() {

    /* Definição de variáveis */


    const [formId, setFormId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [schoolLevel, setSchoolLevel] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [haveEbike, setHaveEbike] = useState('')
    const [howManyTime, setHowManyTime] = useState('')
    const [buyMeans, setBuyMeans] = useState('');
    const [otherBuyMeans, setOtherBuyMeans] = useState('');
    const [means, setMeans] = useState('');
    const [otherMeans, setOtherMeans] = useState('');
    const [resources, setResources] = useState([])
    const [otherResource, setOtherResource] = useState('');
    const [kitType, setKitType] = useState('');
    const [importantThing, setImportantThing] = useState([]);
    const [otherImportantThing, setOtherImportantThing] = useState('');
    const [valueComparison, setValueComparison] = useState('');
    const [influenceFactors, setInfluenceFactors] = useState([]);
    const [otherInfluenceFactors, setOtherInfluenceFactors] = useState('');
    /*     const [spentRange, setSpentRange] = useState(''); */
    const [comments, setComments] = useState('')
    const [subscription, setSubscription] = useState('');
    const [terms, setTerms] = useState(false);
    const [activatedButton, setActivatedButton] = useState(true);
    const [howManyTimeShow, setHowManyTimeShow] = useState(false);
    const [buyMeansShow, setBuyMeansShow] = useState(false);
    const [meansShow, setMeansShow] = useState(false);
    const [submittedScreen, setSubmittedScreen] = useState(true);
    const [ticketShow, setTicketShow] = useState(false);
    const [rescueButton, setRescueButton] = useState(false);
    const [sliderValue, setSliderValue] = useState([3500, 7000]);


    const brazilianStates = [
        'Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 'Distrito Federal',
        'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 'Mato Grosso do Sul',
        'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 'Piauí', 'Rio de Janeiro',
        'Rio Grande do Norte', 'Rio Grande do Sul', 'Rondônia', 'Roraima', 'Santa Catarina',
        'São Paulo', 'Sergipe', 'Tocantins'
    ];


    // Referência para o elemento h5
    const textToCopyRef = useRef(null);

    // Função para copiar o conteúdo do elemento h5 para a área de transferência
    const copyTextToClipboard = () => {
        const textToCopy = textToCopyRef.current.textContent;

        if (navigator.clipboard) {
            // Utilize a API navigator.clipboard
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    // Feedback ao usuário
                    alert('Texto copiado para a área de transferência!');
                })
                .catch((error) => {
                    console.error('Erro ao copiar texto para a área de transferência:', error);
                    // Fallback para a abordagem existente
                    fallbackCopyTextToClipboard(textToCopy);
                });
        } else {
            // Fallback para a abordagem existente
            fallbackCopyTextToClipboard(textToCopy);
        }
    };

    // Função de fallback para a abordagem existente
    const fallbackCopyTextToClipboard = (textToCopy) => {
        // Cria um elemento de texto temporário para copiar o conteúdo
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = textToCopy;
        document.body.appendChild(tempTextArea);

        // Seleciona o texto no elemento de texto temporário
        tempTextArea.select();
        document.execCommand('copy');

        // Remove o elemento de texto temporário
        document.body.removeChild(tempTextArea);

        // Feedback ao usuário
        alert('Texto copiado para a área de transferência!');
    };


    // Função para gerar um novo ID único
    const generateUniqueId = () => {
        const uniqueId = uuidv4();
        setFormId(uniqueId); // Defina o ID gerado no estado do componente
    };





    const ticketButton = () => {
        setTicketShow(true)
        setRescueButton(true)
    }

    useEffect(() => {
        const fullfilled = () => {
            if (
                name !== '' &&
                email !== '' &&
                email !== '' &&
                gender !== '' &&
                age !== '' &&
                schoolLevel !== '' &&
                state !== '' &&
                city !== '' &&
                haveEbike !== '' &&
                (buyMeans !== '' || means !== '') &&
                resources.length > 0 &&
                kitType !== '' &&
                importantThing.length > 0 &&
                valueComparison !== '' &&
                influenceFactors.length > 0 &&
                subscription !== '' &&
                terms !== false &&
                (!resources.includes("8") || (resources.includes("8") && otherResource !== '')) &&

                (!buyMeans.includes("4") || (buyMeans.includes("4") && otherBuyMeans !== '')) &&
                (!means.includes("4") || (means.includes("4") && otherMeans !== '')) &&
                (!importantThing.includes("5") || (importantThing.includes("5") && otherImportantThing !== '')) &&
                (!influenceFactors.includes("4") || (influenceFactors.includes("4") && otherInfluenceFactors !== ''))
            ) {
                setActivatedButton(false);
            } else {
                setActivatedButton(true);
            }
        };

        fullfilled();
    }, [name, email, cpf, gender, age, schoolLevel, state, city, haveEbike, buyMeans, otherBuyMeans, means, otherMeans, resources, otherResource, kitType, importantThing, otherImportantThing, valueComparison, influenceFactors, otherInfluenceFactors, subscription, terms, sliderValue]);

    function formatarNumeroComSeparadores(numero) {
        return numero.toLocaleString('pt-BR'); // Usando a localização brasileira para os separadores de milhar
    }

    const handleChange = (event, fieldName) => {
        let value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        } else if (event.target.type === 'radio') {
            value = event.target.value;
        } else {
            value = event.target.value;
        }

        switch (fieldName) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'cpf':
                setCpf(value);
                break;
            case 'gender':
                setGender(value);
                break;
            case 'age':
                setAge(value);
                break;
            case 'schoolLevel':
                setSchoolLevel(value);
                break;
            case 'state':
                setState(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'haveEbike':
                setHaveEbike(value);
                setHowManyTimeShow(value); // Se 'value' for true, mostra o campo howManyTime
                setMeansShow(value); // Se 'value' for true, mostra o campo Means
                setBuyMeansShow(value); // Se 'value' for false, mostra o campo BuyMeans
                break;
            case 'howManyTime':
                setHowManyTime(value);
                break;
            case 'buyMeans':
                setBuyMeans(value);
                break;
            case 'otherBuyMeans':
                setOtherBuyMeans(value);
                break;
            case 'means':
                setMeans(value);
                break;
            case 'otherMeans':
                setOtherMeans(value);
                break;
            case 'resources':
                if (event.target.checked) {
                    setResources(prevResources => [...prevResources, event.target.value]);
                } else {
                    setResources(prevResources => prevResources.filter(option => option !== event.target.value));
                }
                break;
            case 'otherResources':
                setOtherResource(value); // Atualiza o estado do campo "Outros"
                break;
            case 'kitType':
                setKitType(value);
                break;
            case 'importantThing':
                if (event.target.checked) {
                    setImportantThing(prevImportantThing => [...prevImportantThing, event.target.value]);
                } else {
                    setImportantThing(prevImportantThing => prevImportantThing.filter(option => option !== event.target.value));
                }
                break;
            case 'otherImportantThing':
                setOtherImportantThing(value); // Atualiza o estado do campo "Outros"
                break;
            case 'valueComparison':
                setValueComparison(value);
                break;
            case 'influenceFactors':
                if (event.target.checked) {
                    setInfluenceFactors(prevInfluenceFactors => [...prevInfluenceFactors, event.target.value]);
                } else {
                    setInfluenceFactors(prevInfluenceFactors => prevInfluenceFactors.filter(option => option !== event.target.value));
                }
                break;
            case 'otherInfluenceFactors':
                setOtherInfluenceFactors(value); // Atualiza o estado do campo "Outros"
                break;
            case 'comments':
                setComments(value);
                break;
            case 'subscription':
                setSubscription(value);
                break;
            case 'terms':
                setTerms(value);
                break;
            default:
                break;
        }
    };


    const handleChange1 = (event, newValue) => {
        setSliderValue(newValue);

    };

    const handleSubmit = (event) => {

        event.preventDefault();
/*         console.log("ID:", formId)
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('CPF:', cpf);
        console.log('Gender:', gender);
        console.log('Age:', age);
        console.log('School Level:', schoolLevel);
        console.log('State:', state);
        console.log('City:', city);
        console.log('HaveEbike:', haveEbike)
        console.log('HowManyTime:', howManyTime)
        console.log('Buy Means:', buyMeans);
        console.log('Other Buy Means:', otherBuyMeans);
        console.log('Means:', means);
        console.log('Other Means:', otherMeans);
        console.log('Important Resources:', resources);
        console.log('Other Important Resources:', otherResource)
        console.log('kitType:', kitType);
        console.log('Important Thing:', importantThing)
        console.log('Other Important Thing:', otherImportantThing)
        console.log('Value Comparison:', valueComparison)
        console.log('Influence Factors:', influenceFactors)
        console.log('Other Influence Factors:', otherInfluenceFactors)
        console.log('sliderValue:', sliderValue)
        console.log('Comments:', comments);
        console.log('Subscription:', subscription)
        console.log('Terms:', terms) */
        setSubmittedScreen(false);


        /* function scrollToCenteredDiv() {
    
            var div1 = document.getElementById("div1")
            var offSetTop = div1.offsetTop
            var elementHeight = div1.offsetHeight
            var windowHeight = window.innerHeight
            var scrollTo = offSetTop - (windowHeight - elementHeight) / 2
    
            window.scrollTo({
                top: scrollTo,
                behavior: 'smooth'
            })
    
        }; */


        //Aqui

        var jsonAPI = JSON.stringify({ formId, name, email, cpf, gender, age, schoolLevel, state, city, haveEbike, howManyTime, buyMeans, otherBuyMeans, means, otherMeans, resources, otherResource, kitType, importantThing, otherImportantThing, valueComparison, influenceFactors, otherInfluenceFactors, comments, subscription, terms, sliderValue })

        fetch('https://subscriptiondb.azurewebsites.net/api/researchform/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: jsonAPI
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao enviar os dados do formulário.');
                }
                return response.text();
            })

            .then(data => {
                // Imprimindo o corpo da resposta
                console.log(data);
                // Você pode fazer o que quiser com os dados aqui
            })

            .catch(error => {
                console.error('Caiu nesse erro aqui:', error);
            });
    };

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isHD = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1280px)' });
    const isFullHD = useMediaQuery({ query: '(min-width: 1281px)' });

    const getFirstName = (name) => {
        const words = name.split(' ');
        return words[0];
    };

    const firstName = getFirstName(name);



    return (
        <div >
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />


            {submittedScreen ? (
                <div className='getOfferDIV'>
                    <Container fluid>
                        <Col >
                            <br></br>
                            <br></br>
                            {/* Logo */}
                            <Row>
                                {isMobile && (
                                    <div className='textGetInTouch'>
                                        <img
                                            src={logoIconGreen}
                                            alt="Em breve"
                                            className="App-logo rotateIcon-mobile"
                                            width={80}
                                        />
                                        <br></br>
                                    </div>
                                )}
                                {isHD && (
                                    <div className='textGetInTouch'>
                                        <img
                                            src={logoIconGreen}
                                            alt="Em breve"
                                            className="App-logo rotateIcon-hd"
                                            width={100}
                                        />
                                    </div>
                                )}
                                {isFullHD && (
                                    <div className='textGetInTouch'>
                                        <img
                                            src={logoIconGreen}
                                            alt="Em breve"
                                            className="App-logo rotateIcon-fullhd"
                                            width={100}
                                        />
                                    </div>
                                )}
                            </Row>
                            <br></br>
                            {/* Título */}
                            <Row className='colAlignCenter'>
                                {isMobile && (
                                    <div>
                                        <h1 className='blackFont' style={{ fontSize: '48px', fontFamily: 'Georama' }}>10% OFF</h1><br></br>
                                    </div>
                                )}
                                {isHD && (
                                    <div>
                                        <h1 className='blackFont' style={{ fontSize: '72px', fontFamily: 'Georama' }}>10% OFF</h1><br></br>
                                    </div>
                                )}
                                {isFullHD && (
                                    <div>
                                        <h1 className='blackFont' style={{ fontSize: '80px', fontFamily: 'Georama' }}>10% OFF</h1><br></br>
                                    </div>
                                )}
                            </Row>
                            {/* Subtítulo */}
                            <Row className='colAlignCenter'>
                                <h5 className='blackFont h5width' style={{ fontFamily: 'Georama' }}>Preencha o formulário abaixo e garanta já esse desconto nas primeiras unidades.<br></br><br></br></h5>
                            </Row>
                            {/* Tempo estimado de preenchimento */}
                            <Row className='colAlignCenter'>
                                <h5 className='grayFont h5width' style={{ fontFamily: 'Georama', fontSize:"16px"}}><i>Tempo estimado para o preenchimento do formulário: 2 min.</i></h5>
                                <br></br>
                            </Row>
                            {/* Formulário */}
                            <Row className='colAlignCenter'>
                                <br></br>
                                {isMobile && (
                                    <Form
                                        className='formsMobile'
                                        onSubmit={handleSubmit}
                                    >
                                        {/* Leads */}

                                        {/* Name */}
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicName">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu nome:"
                                                className='floatingClass'
                                            >
                                                <Form.Control
                                                    className='formsInside'
                                                    type="text"
                                                    placeholder="Seu nome:"
                                                    value={name}
                                                    onChange={(event) => handleChange(event, 'name')} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* Email */}
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu email:"
                                                className='floatingClass'
                                            >
                                                <Form.Control className='formsInside' type="email" placeholder="seuemail@example.com" value={email} onChange={(event) => handleChange(event, 'email')} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* CPF */}
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCpf">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu CPF:"
                                                className='floatingClass'
                                            >
                                                <Form.Control
                                                    className='formsInside'
                                                    as={InputMask}
                                                    mask="999.999.999-99"
                                                    placeholder="Seu CPF:"
                                                    value={cpf}
                                                    onChange={(event) => handleChange(event, 'cpf')}
                                                    type="tel"
                                                />
                                            </FloatingLabel>
                                        </Form.Group>

                                        {/* Público */}

                                        {/* Gender */}
                                        <Form.Group className="mb-3" controlId="formBasicGender">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Gênero:</Form.Label>
                                            <Form.Check
                                                label="Masculino"
                                                name="gender"
                                                type="radio"
                                                id="Masculino"
                                                value="1"
                                                checked={gender === "1"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Feminino"
                                                name="gender"
                                                type="radio"
                                                id="Feminino"
                                                value="2"
                                                checked={gender === "2"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Prefiro não dizer"
                                                name="gender"
                                                type="radio"
                                                id="PrefiroNaoDizer"
                                                value="3"
                                                checked={gender === "3"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* Age */}
                                        <Form.Group className="mb-3" controlId="formBasicAge">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Data de nascimento:</Form.Label>
                                            <Form.Control
                                                className='formsInside'
                                                type="date"
                                                value={age}
                                                onChange={(event) => handleChange(event, 'age')} />
                                        </Form.Group>
                                        {/* School Level */}
                                        <Form.Group className="mb-3" controlId="formBasicSchoolLevel">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Escolaridade:</Form.Label>
                                            <Form.Check
                                                label="Fundamental completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Fundamental"
                                                value="1"
                                                checked={schoolLevel === "1"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Médio completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Medio"
                                                value="2"
                                                checked={schoolLevel === "2"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Superior completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Superior"
                                                value="3"
                                                checked={schoolLevel === "3"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>


                                        {/* Dados demográficos */}

                                        {/* State */}
                                        <Form.Group className="mb-3" controlId="formBasicState">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu estado:"
                                                className="floatingClass"
                                            >
                                                <Form.Select
                                                    className='formsInside'
                                                    value={state}
                                                    style={{ height: 'auto' }}
                                                    onChange={(event) => handleChange(event, 'state')}>
                                                    <option value=""></option>
                                                    {brazilianStates.map((brazilianStates, index) => (
                                                        <option key={index} value={brazilianStates}>{brazilianStates}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* City */}
                                        <Form.Group className="mb-3" controlId="formBasicCity">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Sua cidade:"
                                                className="floatingClass"
                                            >
                                                <Form.Control className='formsInside' type="text" placeholder="Sua cidade:" value={city} onChange={(event) => handleChange(event, 'city')} />
                                            </FloatingLabel>
                                        </Form.Group>

                                        {/* Experiência do consumidor */}

                                        {/* HaveEbike? */}
                                        <Form.Group className="mb-3" controlId="formBasicHaveEbike">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você atualmente possui ou já possuiu uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Sim"
                                                name="haveEbike"
                                                id="yes"
                                                type="radio"
                                                value={true}
                                                onChange={(event) => handleChange(event, 'haveEbike')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não"
                                                name="haveEbike"
                                                id="no"
                                                type="radio"
                                                value={false}
                                                onChange={(event) => handleChange(event, 'haveEbike')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* HowManyTime? */}
                                        {howManyTimeShow === "true" && (
                                            <Form.Group className="mb-3" controlId="formBasicHaveEbike">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Há quanto tempo você a possui ou possuiu?</Form.Label>
                                                <Form.Check
                                                    label="Menos de 1 ano"
                                                    name="howManyTime"
                                                    id="<1"
                                                    type="radio"
                                                    value="1"
                                                    checked={howManyTime === "1"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Entre 1 e 3 anos"
                                                    name="howManyTime"
                                                    id="1<X<3"
                                                    type="radio"
                                                    value="2"
                                                    checked={howManyTime === "2"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Mais de 3 anos"
                                                    name="howManyTime"
                                                    id=">3"
                                                    type="radio"
                                                    value="3"
                                                    checked={howManyTime === "3"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                            </Form.Group>
                                        )}
                                        {/* Interesses e Preferências */}

                                        {/* Buy Means */}
                                        {buyMeansShow === "false" && (
                                            <Form.Group className="mb-3" controlId="formBasicBuyMeans">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual o principal uso que você daria a uma bicicleta elétrica?</Form.Label>
                                                <Form.Check
                                                    label="Meio de locomoção"
                                                    name="buyMeans"
                                                    id="transport"
                                                    type="radio"
                                                    value="1"
                                                    checked={buyMeans === "1"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Prática de esporte"
                                                    name="buyMeans"
                                                    id="sports"
                                                    type="radio"
                                                    value="2"
                                                    checked={buyMeans === "2"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Lazer"
                                                    name="buyMeans"
                                                    id="fun"
                                                    type="radio"
                                                    value="3"
                                                    checked={buyMeans === "3"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Outro"
                                                    name="buyMeans"
                                                    id="other"
                                                    type="radio"
                                                    value="4"
                                                    checked={buyMeans === "4"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                {buyMeans.includes("4") && (
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Outro:"
                                                        className="floatingClass"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            value={otherBuyMeans}
                                                            onChange={(event) => handleChange(event, 'otherBuyMeans')}
                                                            className='formsInside form-control'
                                                        />
                                                    </FloatingLabel>
                                                )}
                                            </Form.Group>
                                        )
                                        }
                                        {/* Means */}
                                        {meansShow === "true" && (
                                            <Form.Group className="mb-3" controlId="formBasicBuyMeans">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual o principal uso de uma bicicleta elétrica para você?</Form.Label>
                                                <Form.Check
                                                    label="Meio de locomoção"
                                                    name="Means"
                                                    id="transport1"
                                                    type="radio"
                                                    value="1"
                                                    checked={means === "1"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Prática de esporte"
                                                    name="Means"
                                                    id="sports1"
                                                    type="radio"
                                                    value="2"
                                                    checked={means === "2"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Lazer"
                                                    name="Means"
                                                    id="fun1"
                                                    type="radio"
                                                    value="3"
                                                    checked={means === "3"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Outro"
                                                    name="Means"
                                                    id="other1"
                                                    type="radio"
                                                    value="4"
                                                    checked={means === "4"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                {means.includes("4") && (
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Outro:"
                                                        className="floatingClass"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            value={otherMeans}
                                                            onChange={(event) => handleChange(event, 'otherMeans')}
                                                            className='formsInside form-control'
                                                        />
                                                    </FloatingLabel>
                                                )}
                                            </Form.Group>)
                                        }
                                        {/* Important Resources */}
                                        <Form.Group className="mb-3" controlId="formBasicImportantResources">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Que tipo de recursos você considera essenciais em uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Autonomia da bateria"
                                                name="importantResources"
                                                id="battery"
                                                type="checkbox"
                                                value="1"
                                                checked={resources.includes("1")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            <Form.Check
                                                label="Velocidade máxima"
                                                name="importantResources"
                                                id="maxvelocity"
                                                type="checkbox"
                                                value="2"
                                                checked={resources.includes("2")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Frenagem regenerativa"
                                                name="importantResources"
                                                id="breaking"
                                                type="checkbox"
                                                value="3"
                                                checked={resources.includes("3")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Pedal Assistido"
                                                name="importantResources"
                                                id="pedal"
                                                type="checkbox"
                                                value="4"
                                                checked={resources.includes("4")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Display informativo"
                                                name="importantResources"
                                                id="display"
                                                type="checkbox"
                                                value="5"
                                                checked={resources.includes("5")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />
                                            {<Form.Check
                                                label="Resiliência para qualquer tipo de terreno"
                                                name="importantResources"
                                                id="terrain"
                                                type="checkbox"
                                                value="6"
                                                checked={resources.includes("6")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {<Form.Check
                                                label="Encontrar a bicicleta pelo GPS"
                                                name="importantResources"
                                                id="GPS"
                                                type="checkbox"
                                                value="7"
                                                checked={resources.includes("7")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {<Form.Check
                                                label="Outros"
                                                name="importantResources"
                                                id="other10"
                                                type="checkbox"
                                                value="8"
                                                checked={resources.includes("8")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {resources.includes("8") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherResource}
                                                        onChange={(event) => handleChange(event, 'otherResources')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}

                                        </Form.Group>
                                        {/* Kit Type */}
                                        <Form.Group className="mb-3" controlId="formBasicKitType">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual opção melhor se encaixa à sua necessidade/realidade?</Form.Label>
                                            <Form.Check
                                                label="Kit para conversão de uma bicicleta comum"
                                                name="kitType"
                                                type="radio"
                                                id="justKit"
                                                value="1"
                                                checked={kitType === "1"}
                                                onChange={(event) => handleChange(event, 'kitType')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="E-bike completa"
                                                name="kitType"
                                                type="radio"
                                                id="full"
                                                value="2"
                                                checked={kitType === "2"}
                                                onChange={(event) => handleChange(event, 'kitType')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>

                                        {/* Percepção de Valor */}

                                        {/* Important Thing */}
                                        <Form.Group className="mb-3" controlId="formBasicImportantThing">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>O que você considera mais importante ao avaliar o custo de uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Qualidade e durabilidade do produto"
                                                name="importantThing"
                                                id="quality3"
                                                type="checkbox"
                                                value="1"
                                                checked={importantThing.includes("1")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Recursos e tecnologia embarcada"
                                                name="importantThing"
                                                id="tecnology3"
                                                type="checkbox"
                                                value="2"
                                                checked={importantThing.includes("2")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Marca reconhecida no mercado"
                                                name="importantThing"
                                                id="brand3"
                                                type="checkbox"
                                                value="3"
                                                checked={importantThing.includes("3")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Preço mais acessível possível"
                                                name="importantThing"
                                                id="price3"
                                                type="checkbox"
                                                value="4"
                                                checked={importantThing.includes("4")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Outros"
                                                name="importantThing"
                                                id="other3"
                                                type="checkbox"
                                                value="5"
                                                checked={importantThing.includes("5")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            {importantThing.includes("5") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherImportantThing}
                                                        onChange={(event) => handleChange(event, 'otherImportantThing')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}
                                        </Form.Group>
                                        {/* Value Comparison */}
                                        <Form.Group className="mb-3" controlId="formBasicValueComparison">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Em comparação com outros meios de transporte (ex: carro, transporte público, bicicleta convencional), você acredita que o investimento em uma bicicleta elétrica é:</Form.Label>
                                            <Form.Check
                                                label="Mais vantajoso financeiramente a longo prazo"
                                                name="valueComparison"
                                                id="longWay4"
                                                type="radio"
                                                value="1"
                                                checked={valueComparison === "1"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Comparável em termos de custo e benefício"
                                                name="valueComparison"
                                                id="comparable4"
                                                type="radio"
                                                value="2"
                                                checked={valueComparison === "2"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Mais caro, mas justificado pelos benefícios oferecidos"
                                                name="valueComparison"
                                                id="expensive4"
                                                type="radio"
                                                value="3"
                                                checked={valueComparison === "3"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não tenho certeza"
                                                name="valueComparison"
                                                id="notsure4"
                                                type="radio"
                                                value="4"
                                                checked={valueComparison === "4"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* Influence Factors */}
                                        <Form.Group className="mb-3" controlId="formBasicInfluence Factors">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Que fatores você considera ao decidir o quanto está disposto a investir em uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Avaliações online e recomendações de especialistas"
                                                name="influenceFactors"
                                                id="online5"
                                                type="checkbox"
                                                value="1"
                                                checked={influenceFactors.includes("1")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Características específicas do produto (ex: autonomia da bateria, design etc.)"
                                                name="influenceFactors"
                                                id="specified5"
                                                type="checkbox"
                                                value="2"
                                                checked={influenceFactors.includes("2")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Preço em relação aos recursos oferecidos"
                                                name="influenceFactors"
                                                id="price5"
                                                type="checkbox"
                                                value="3"
                                                checked={influenceFactors.includes("3")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Outros"
                                                name="influenceFactors"
                                                id="other5"
                                                type="checkbox"
                                                value="4"
                                                checked={influenceFactors.includes("4")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />
                                            {influenceFactors.includes("4") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherInfluenceFactors}
                                                        onChange={(event) => handleChange(event, 'otherInfluenceFactors')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}
                                        </Form.Group>

                                        {/* Value Range */}


                                        {/* Spent Range */}
                                        <Form.Group className="mb-3" controlId="formBasicSpentRange">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Quanto você estaria disposto a investir em uma bicicleta elétrica de alta qualidade?</Form.Label>
                                            <br></br>
                                            <div>
                                                <h3 style={{ fontSize: '18px', fontFamily: 'Georama' }}>DE R$<b>{formatarNumeroComSeparadores(sliderValue[0])}</b> ATÉ R$<b>{formatarNumeroComSeparadores(sliderValue[1])}</b></h3>
                                            </div>
                                            <Box>
                                                <br></br>
                                                <Slider
                                                    sx={{
                                                        color: '#32a283',
                                                        '& .MuiSlider-thumb': {
                                                            height: 27,
                                                            width: 27,
                                                            backgroundColor: '#fff',
                                                            border: '1px solid currentColor',
                                                            '&:hover': {
                                                                boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                                                            },
                                                            '& .airbnb-bar': {
                                                                height: 9,
                                                                width: 1,
                                                                backgroundColor: 'currentColor',
                                                                marginLeft: 1,
                                                                marginRight: 1,
                                                            },
                                                        },
                                                        '& .MuiSlider-track': {
                                                            height: 3,
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            color: '#e65025',
                                                            opacity: 0.5,
                                                            height: 3,
                                                        },
                                                    }}

                                                    value={sliderValue}
                                                    onChange={handleChange1}
                                                    valueLabelDisplay="auto"
                                                    min={2000}
                                                    max={18000}
                                                    step={500}
                                                    /*                                                     getAriaValueText={valuetext} */
                                                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                                />
                                            </Box>

                                        </Form.Group>


                                        {/* Aditional Comments */}
                                        <Form.Group className="mb-3" controlId="formBasicAditionalComment">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Caso tenha algum comentário adicional sobre bicicletas elétricas ou sobre esta pesquisa, por favor, sinta-se à vontade para compartilhá-lo:</Form.Label>
                                            <Form.Control
                                                className='formsInside'
                                                as="textarea"
                                                placeholder=" Escreva aqui :)"
                                                value={comments}
                                                onChange={(event) => handleChange(event, 'comments')} />
                                        </Form.Group>

                                        {/* Subscription */}
                                        <Form.Group className="mb-3" controlId="formBasicSubscription">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você gostaria de receber informações sobre promoções, novos modelos ou eventos relacionados a bicicletas elétricas?</Form.Label>
                                            <Form.Check
                                                label="Sim"
                                                name="subscription"
                                                id="yes6"
                                                type="radio"
                                                value={true}
                                                onChange={(event) => handleChange(event, 'subscription')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não"
                                                name="subscription"
                                                id="no6"
                                                type="radio"
                                                value={false}
                                                onChange={(event) => handleChange(event, 'subscription')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>

                                        {/* Aceite dos termos */}
                                        <Form.Group className="mb-3" controlId="formBasicTerms">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Aceite os Termos e Condições deste desconto para finalizar:</Form.Label>
                                            <Form.Check
                                                label={
                                                    <span>
                                                        Aceito os <a href="/offerTerms" target="_blank" rel="noopener noreferrer" style={{ color: '#156da8' }}>Termos e Condições.</a>
                                                    </span>

                                                }
                                                className='switch-with-margin'
                                                name='Terms'
                                                id='terms1'
                                                type='switch'
                                                value={true}
                                                onChange={(event) => handleChange(event, 'terms')}
                                            />
                                        </Form.Group>
                                        <br></br>
                                        {/* Botão Enviar*/}
                                        <Button className='sendButton' onClick={generateUniqueId} disabled={activatedButton} type="submit" style={{ fontFamily: 'Georama' }}>ENVIAR</Button>{' '}
                                        <br></br>
                                    </Form>
                                )}
                                {isHD && (
                                    <Form
                                        className='formsHD'
                                        onSubmit={handleSubmit}
                                    >
                                        {/* Leads */}

                                        {/* Name */}
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicName">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu nome:"
                                                className='floatingClass'
                                            >
                                                <Form.Control
                                                    className='formsInside'
                                                    type="text"
                                                    placeholder="Seu nome:"
                                                    value={name}
                                                    onChange={(event) => handleChange(event, 'name')} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* Email */}
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu email:"
                                                className='floatingClass'
                                            >
                                                <Form.Control className='formsInside' type="email" placeholder="seuemail@example.com" value={email} onChange={(event) => handleChange(event, 'email')} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* CPF */}
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCpf">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu CPF:"
                                                className='floatingClass'
                                            >
                                                <Form.Control
                                                    className='formsInside'
                                                    as={InputMask}
                                                    mask="999.999.999-99"
                                                    placeholder="Seu CPF:"
                                                    value={cpf}
                                                    onChange={(event) => handleChange(event, 'cpf')} />
                                            </FloatingLabel>
                                        </Form.Group>

                                        {/* Público */}

                                        {/* Gender */}
                                        <Form.Group className="mb-3" controlId="formBasicGender">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Gênero:</Form.Label>
                                            <Form.Check
                                                label="Masculino"
                                                name="gender"
                                                type="radio"
                                                id="Masculino"
                                                value="1"
                                                checked={gender === "1"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Feminino"
                                                name="gender"
                                                type="radio"
                                                id="Feminino"
                                                value="2"
                                                checked={gender === "2"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Prefiro não dizer"
                                                name="gender"
                                                type="radio"
                                                id="PrefiroNaoDizer"
                                                value="3"
                                                checked={gender === "3"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* Age */}
                                        <Form.Group className="mb-3" controlId="formBasicAge">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Data de nascimento:</Form.Label>
                                            <Form.Control
                                                className='formsInside'
                                                type="date"
                                                value={age}
                                                onChange={(event) => handleChange(event, 'age')} />
                                        </Form.Group>
                                        {/* School Level */}
                                        <Form.Group className="mb-3" controlId="formBasicSchoolLevel">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Escolaridade:</Form.Label>
                                            <Form.Check
                                                label="Fundamental completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Fundamental"
                                                value="1"
                                                checked={schoolLevel === "1"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Médio completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Medio"
                                                value="2"
                                                checked={schoolLevel === "2"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Superior completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Superior"
                                                value="3"
                                                checked={schoolLevel === "3"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>


                                        {/* Dados demográficos */}

                                        {/* State */}
                                        <Form.Group className="mb-3" controlId="formBasicState">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu estado:"
                                                className="floatingClass"
                                            >
                                                <Form.Select
                                                    className='formsInside'
                                                    value={state}
                                                    style={{ height: 'auto' }}
                                                    onChange={(event) => handleChange(event, 'state')}>
                                                    <option value=""></option>
                                                    {brazilianStates.map((brazilianStates, index) => (
                                                        <option key={index} value={brazilianStates}>{brazilianStates}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* City */}
                                        <Form.Group className="mb-3" controlId="formBasicCity">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Sua cidade:"
                                                className="floatingClass"
                                            >
                                                <Form.Control className='formsInside' type="text" placeholder="Sua cidade:" value={city} onChange={(event) => handleChange(event, 'city')} />
                                            </FloatingLabel>
                                        </Form.Group>

                                        {/* Experiência do consumidor */}

                                        {/* HaveEbike? */}
                                        <Form.Group className="mb-3" controlId="formBasicHaveEbike">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você atualmente possui ou já possuiu uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Sim"
                                                name="haveEbike"
                                                id="yes"
                                                type="radio"
                                                value={true}
                                                onChange={(event) => handleChange(event, 'haveEbike')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não"
                                                name="haveEbike"
                                                id="no"
                                                type="radio"
                                                value={false}
                                                onChange={(event) => handleChange(event, 'haveEbike')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* HowManyTime? */}
                                        {howManyTimeShow === "true" && (
                                            <Form.Group className="mb-3" controlId="formBasicHaveEbike">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Há quanto tempo você a possui ou possuiu?</Form.Label>
                                                <Form.Check
                                                    label="Menos de 1 ano"
                                                    name="howManyTime"
                                                    id="<1"
                                                    type="radio"
                                                    value="1"
                                                    checked={howManyTime === "1"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Entre 1 e 3 anos"
                                                    name="howManyTime"
                                                    id="1<X<3"
                                                    type="radio"
                                                    value="2"
                                                    checked={howManyTime === "2"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Mais de 3 anos"
                                                    name="howManyTime"
                                                    id=">3"
                                                    type="radio"
                                                    value="3"
                                                    checked={howManyTime === "3"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                            </Form.Group>
                                        )}
                                        {/* Interesses e Preferências */}

                                        {/* Buy Means */}
                                        {buyMeansShow === "false" && (
                                            <Form.Group className="mb-3" controlId="formBasicBuyMeans">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual o principal uso que você daria a uma bicicleta elétrica?</Form.Label>
                                                <Form.Check
                                                    label="Meio de locomoção"
                                                    name="buyMeans"
                                                    id="transport"
                                                    type="radio"
                                                    value="1"
                                                    checked={buyMeans === "1"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Prática de esporte"
                                                    name="buyMeans"
                                                    id="sports"
                                                    type="radio"
                                                    value="2"
                                                    checked={buyMeans === "2"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Lazer"
                                                    name="buyMeans"
                                                    id="fun"
                                                    type="radio"
                                                    value="3"
                                                    checked={buyMeans === "3"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Outro"
                                                    name="buyMeans"
                                                    id="other"
                                                    type="radio"
                                                    value="4"
                                                    checked={buyMeans === "4"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                {buyMeans.includes("4") && (
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Outro:"
                                                        className="floatingClass"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            value={otherBuyMeans}
                                                            onChange={(event) => handleChange(event, 'otherBuyMeans')}
                                                            className='formsInside form-control'
                                                        />
                                                    </FloatingLabel>
                                                )}
                                            </Form.Group>
                                        )
                                        }
                                        {/* Means */}
                                        {meansShow === "true" && (
                                            <Form.Group className="mb-3" controlId="formBasicBuyMeans">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual o principal uso de uma bicicleta elétrica para você?</Form.Label>
                                                <Form.Check
                                                    label="Meio de locomoção"
                                                    name="Means"
                                                    id="transport1"
                                                    type="radio"
                                                    value="1"
                                                    checked={means === "1"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Prática de esporte"
                                                    name="Means"
                                                    id="sports1"
                                                    type="radio"
                                                    value="2"
                                                    checked={means === "2"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Lazer"
                                                    name="Means"
                                                    id="fun1"
                                                    type="radio"
                                                    value="3"
                                                    checked={means === "3"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Outro"
                                                    name="Means"
                                                    id="other1"
                                                    type="radio"
                                                    value="4"
                                                    checked={means === "4"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                {means.includes("4") && (
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Outro:"
                                                        className="floatingClass"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            value={otherMeans}
                                                            onChange={(event) => handleChange(event, 'otherMeans')}
                                                            className='formsInside form-control'
                                                        />
                                                    </FloatingLabel>
                                                )}
                                            </Form.Group>)
                                        }
                                        {/* Important Resources */}
                                        <Form.Group className="mb-3" controlId="formBasicImportantResources">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Que tipo de recursos você considera essenciais em uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Autonomia da bateria"
                                                name="importantResources"
                                                id="battery"
                                                type="checkbox"
                                                value="1"
                                                checked={resources.includes("1")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            <Form.Check
                                                label="Velocidade máxima"
                                                name="importantResources"
                                                id="maxvelocity"
                                                type="checkbox"
                                                value="2"
                                                checked={resources.includes("2")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Frenagem regenerativa"
                                                name="importantResources"
                                                id="breaking"
                                                type="checkbox"
                                                value="3"
                                                checked={resources.includes("3")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Pedal Assistido"
                                                name="importantResources"
                                                id="pedal"
                                                type="checkbox"
                                                value="4"
                                                checked={resources.includes("4")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Display informativo"
                                                name="importantResources"
                                                id="display"
                                                type="checkbox"
                                                value="5"
                                                checked={resources.includes("5")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />
                                            {<Form.Check
                                                label="Resiliência para qualquer tipo de terreno"
                                                name="importantResources"
                                                id="terrain"
                                                type="checkbox"
                                                value="6"
                                                checked={resources.includes("6")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {<Form.Check
                                                label="Encontrar a bicicleta pelo GPS"
                                                name="importantResources"
                                                id="GPS"
                                                type="checkbox"
                                                value="7"
                                                checked={resources.includes("7")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {<Form.Check
                                                label="Outros"
                                                name="importantResources"
                                                id="other10"
                                                type="checkbox"
                                                value="8"
                                                checked={resources.includes("8")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {resources.includes("8") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherResource}
                                                        onChange={(event) => handleChange(event, 'otherResources')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}

                                        </Form.Group>
                                        {/* Kit Type */}
                                        <Form.Group className="mb-3" controlId="formBasicKitType">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual opção melhor se encaixa à sua necessidade/realidade?</Form.Label>
                                            <Form.Check
                                                label="Kit para conversão de uma bicicleta comum"
                                                name="kitType"
                                                type="radio"
                                                id="justKit"
                                                value="1"
                                                checked={kitType === "1"}
                                                onChange={(event) => handleChange(event, 'kitType')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="E-bike completa"
                                                name="kitType"
                                                type="radio"
                                                id="full"
                                                value="2"
                                                checked={kitType === "2"}
                                                onChange={(event) => handleChange(event, 'kitType')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>

                                        {/* Percepção de Valor */}

                                        {/* Important Thing */}
                                        <Form.Group className="mb-3" controlId="formBasicImportantThing">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>O que você considera mais importante ao avaliar o custo de uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Qualidade e durabilidade do produto"
                                                name="importantThing"
                                                id="quality3"
                                                type="checkbox"
                                                value="1"
                                                checked={importantThing.includes("1")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Recursos e tecnologia embarcada"
                                                name="importantThing"
                                                id="tecnology3"
                                                type="checkbox"
                                                value="2"
                                                checked={importantThing.includes("2")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Marca reconhecida no mercado"
                                                name="importantThing"
                                                id="brand3"
                                                type="checkbox"
                                                value="3"
                                                checked={importantThing.includes("3")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Preço mais acessível possível"
                                                name="importantThing"
                                                id="price3"
                                                type="checkbox"
                                                value="4"
                                                checked={importantThing.includes("4")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Outros"
                                                name="importantThing"
                                                id="other3"
                                                type="checkbox"
                                                value="5"
                                                checked={importantThing.includes("5")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            {importantThing.includes("5") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherImportantThing}
                                                        onChange={(event) => handleChange(event, 'otherImportantThing')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}
                                        </Form.Group>
                                        {/* Value Comparison */}
                                        <Form.Group className="mb-3" controlId="formBasicValueComparison">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Em comparação com outros meios de transporte (ex: carro, transporte público, bicicleta convencional), você acredita que o investimento em uma bicicleta elétrica é:</Form.Label>
                                            <Form.Check
                                                label="Mais vantajoso financeiramente a longo prazo"
                                                name="valueComparison"
                                                id="longWay4"
                                                type="radio"
                                                value="1"
                                                checked={valueComparison === "1"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Comparável em termos de custo e benefício"
                                                name="valueComparison"
                                                id="comparable4"
                                                type="radio"
                                                value="2"
                                                checked={valueComparison === "2"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Mais caro, mas justificado pelos benefícios oferecidos"
                                                name="valueComparison"
                                                id="expensive4"
                                                type="radio"
                                                value="3"
                                                checked={valueComparison === "3"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não tenho certeza"
                                                name="valueComparison"
                                                id="notsure4"
                                                type="radio"
                                                value="4"
                                                checked={valueComparison === "4"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* Influence Factors */}
                                        <Form.Group className="mb-3" controlId="formBasicInfluence Factors">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Que fatores você considera ao decidir o quanto está disposto a investir em uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Avaliações online e recomendações de especialistas"
                                                name="influenceFactors"
                                                id="online5"
                                                type="checkbox"
                                                value="1"
                                                checked={influenceFactors.includes("1")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Características específicas do produto (ex: autonomia da bateria, design etc.)"
                                                name="influenceFactors"
                                                id="specified5"
                                                type="checkbox"
                                                value="2"
                                                checked={influenceFactors.includes("2")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Preço em relação aos recursos oferecidos"
                                                name="influenceFactors"
                                                id="price5"
                                                type="checkbox"
                                                value="3"
                                                checked={influenceFactors.includes("3")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Outros"
                                                name="influenceFactors"
                                                id="other5"
                                                type="checkbox"
                                                value="4"
                                                checked={influenceFactors.includes("4")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />
                                            {influenceFactors.includes("4") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherInfluenceFactors}
                                                        onChange={(event) => handleChange(event, 'otherInfluenceFactors')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}
                                        </Form.Group>
                                        {/* Spent Range */}
                                        <Form.Group className="mb-3" controlId="formBasicSpentRange">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Quanto você estaria disposto a investir em uma bicicleta elétrica de alta qualidade?</Form.Label>
                                            <br></br>
                                            <div>
                                                <h3 style={{ fontSize: '18px', fontFamily: 'Georama' }}>DE R$<b>{formatarNumeroComSeparadores(sliderValue[0])}</b> ATÉ R$<b>{formatarNumeroComSeparadores(sliderValue[1])}</b></h3>
                                            </div>
                                            <Box>
                                                <br></br>
                                                <Slider
                                                    sx={{
                                                        color: '#32a283',
                                                        '& .MuiSlider-thumb': {
                                                            height: 27,
                                                            width: 27,
                                                            backgroundColor: '#fff',
                                                            border: '1px solid currentColor',
                                                            '&:hover': {
                                                                boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                                                            },
                                                            '& .airbnb-bar': {
                                                                height: 9,
                                                                width: 1,
                                                                backgroundColor: 'currentColor',
                                                                marginLeft: 1,
                                                                marginRight: 1,
                                                            },
                                                        },
                                                        '& .MuiSlider-track': {
                                                            height: 3,
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            color: '#e65025',
                                                            opacity: 0.5,
                                                            height: 3,
                                                        },
                                                    }}

                                                    value={sliderValue}
                                                    onChange={handleChange1}
                                                    valueLabelDisplay="auto"
                                                    min={2000}
                                                    max={18000}
                                                    step={500}
                                                    /*                                                     getAriaValueText={valuetext} */
                                                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                                />
                                            </Box>

                                        </Form.Group>


                                        {/* Aditional Comments */}
                                        <Form.Group className="mb-3" controlId="formBasicAditionalComment">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Caso tenha algum comentário adicional sobre bicicletas elétricas ou sobre esta pesquisa, por favor, sinta-se à vontade para compartilhá-lo:</Form.Label>
                                            <Form.Control
                                                className='formsInside'
                                                as="textarea"
                                                placeholder=" Escreva aqui :)"
                                                value={comments}
                                                onChange={(event) => handleChange(event, 'comments')} />
                                        </Form.Group>

                                        {/* Subscription */}
                                        <Form.Group className="mb-3" controlId="formBasicSubscription">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você gostaria de receber informações sobre promoções, novos modelos ou eventos relacionados a bicicletas elétricas?</Form.Label>
                                            <Form.Check
                                                label="Sim"
                                                name="subscription"
                                                id="yes6"
                                                type="radio"
                                                value={true}
                                                onChange={(event) => handleChange(event, 'subscription')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não"
                                                name="subscription"
                                                id="no6"
                                                type="radio"
                                                value={false}
                                                onChange={(event) => handleChange(event, 'subscription')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>

                                        {/* Aceite dos termos */}

                                        <Form.Group className="mb-3" controlId="formBasicTerms">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Aceite os Termos e Condições deste desconto para finalizar:</Form.Label>
                                            <Form.Check
                                                label={
                                                    <span>
                                                        Aceito os <a href="/offerTerms" target="_blank" rel="noopener noreferrer" style={{ color: '#156da8' }}>Termos e Condições.</a>
                                                    </span>

                                                }
                                                className='switch-with-margin'
                                                name='Terms'
                                                id='terms1'
                                                type='switch'
                                                value={true}
                                                onChange={(event) => handleChange(event, 'terms')}
                                            />
                                        </Form.Group>
                                        <br></br>
                                        {/* Botão Enviar*/}
                                        <Button className='sendButton' onClick={generateUniqueId} disabled={activatedButton} type="submit" style={{ fontFamily: 'Georama' }}>ENVIAR</Button>{' '}
                                        <br></br>
                                    </Form>
                                )}
                                {isFullHD && (
                                    <Form
                                        className='formsFullHD'
                                        onSubmit={handleSubmit}
                                    >
                                        {/* Leads */}

                                        {/* Name */}
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicName">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu nome:"
                                                className='floatingClass'
                                            >
                                                <Form.Control
                                                    className='formsInside'
                                                    type="text"
                                                    placeholder="Seu nome:"
                                                    value={name}
                                                    onChange={(event) => handleChange(event, 'name')} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* Email */}
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu email:"
                                                className='floatingClass'
                                            >
                                                <Form.Control className='formsInside' type="email" placeholder="seuemail@example.com" value={email} onChange={(event) => handleChange(event, 'email')} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* CPF */}
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCpf">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu CPF:"
                                                className='floatingClass'
                                            >
                                                <Form.Control
                                                    className='formsInside'
                                                    as={InputMask}
                                                    mask="999.999.999-99"
                                                    placeholder="Seu CPF:"
                                                    value={cpf}
                                                    onChange={(event) => handleChange(event, 'cpf')} />
                                            </FloatingLabel>
                                        </Form.Group>

                                        {/* Público */}

                                        {/* Gender */}
                                        <Form.Group className="mb-3" controlId="formBasicGender">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Gênero:</Form.Label>
                                            <Form.Check
                                                label="Masculino"
                                                name="gender"
                                                type="radio"
                                                id="Masculino"
                                                value="1"
                                                checked={gender === "1"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Feminino"
                                                name="gender"
                                                type="radio"
                                                id="Feminino"
                                                value="2"
                                                checked={gender === "2"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Prefiro não dizer"
                                                name="gender"
                                                type="radio"
                                                id="PrefiroNaoDizer"
                                                value="3"
                                                checked={gender === "3"}
                                                onChange={(event) => handleChange(event, 'gender')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* Age */}
                                        <Form.Group className="mb-3" controlId="formBasicAge">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Data de nascimento:</Form.Label>
                                            <Form.Control
                                                className='formsInside'
                                                type="date"
                                                value={age}
                                                onChange={(event) => handleChange(event, 'age')} />
                                        </Form.Group>
                                        {/* School Level */}
                                        <Form.Group className="mb-3" controlId="formBasicSchoolLevel">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Escolaridade:</Form.Label>
                                            <Form.Check
                                                label="Fundamental completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Fundamental"
                                                value="1"
                                                checked={schoolLevel === "1"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Médio completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Medio"
                                                value="2"
                                                checked={schoolLevel === "2"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Superior completo"
                                                name="schoolLevel"
                                                type="radio"
                                                id="Superior"
                                                value="3"
                                                checked={schoolLevel === "3"}
                                                onChange={(event) => handleChange(event, 'schoolLevel')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>


                                        {/* Dados demográficos */}

                                        {/* State */}
                                        <Form.Group className="mb-3" controlId="formBasicState">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Seu estado:"
                                                className="floatingClass"
                                            >
                                                <Form.Select
                                                    className='formsInside'
                                                    value={state}
                                                    style={{ height: 'auto' }}
                                                    onChange={(event) => handleChange(event, 'state')}>
                                                    <option value=""></option>
                                                    {brazilianStates.map((brazilianStates, index) => (
                                                        <option key={index} value={brazilianStates}>{brazilianStates}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Form.Group>
                                        {/* City */}
                                        <Form.Group className="mb-3" controlId="formBasicCity">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Sua cidade:"
                                                className="floatingClass"
                                            >
                                                <Form.Control className='formsInside' type="text" placeholder="Sua cidade:" value={city} onChange={(event) => handleChange(event, 'city')} />
                                            </FloatingLabel>
                                        </Form.Group>

                                        {/* Experiência do consumidor */}

                                        {/* HaveEbike? */}
                                        <Form.Group className="mb-3" controlId="formBasicHaveEbike">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você atualmente possui ou já possuiu uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Sim"
                                                name="haveEbike"
                                                id="yes"
                                                type="radio"
                                                value={true}
                                                onChange={(event) => handleChange(event, 'haveEbike')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não"
                                                name="haveEbike"
                                                id="no"
                                                type="radio"
                                                value={false}
                                                onChange={(event) => handleChange(event, 'haveEbike')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* HowManyTime? */}
                                        {howManyTimeShow === "true" && (
                                            <Form.Group className="mb-3" controlId="formBasicHaveEbike">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Há quanto tempo você a possui ou possuiu?</Form.Label>
                                                <Form.Check
                                                    label="Menos de 1 ano"
                                                    name="howManyTime"
                                                    id="<1"
                                                    type="radio"
                                                    value="1"
                                                    checked={howManyTime === "1"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Entre 1 e 3 anos"
                                                    name="howManyTime"
                                                    id="1<X<3"
                                                    type="radio"
                                                    value="2"
                                                    checked={howManyTime === "2"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Mais de 3 anos"
                                                    name="howManyTime"
                                                    id=">3"
                                                    type="radio"
                                                    value="3"
                                                    checked={howManyTime === "3"}
                                                    onChange={(event) => handleChange(event, 'howManyTime')}
                                                    className="checkbox-with-margin"
                                                />
                                            </Form.Group>
                                        )}
                                        {/* Interesses e Preferências */}

                                        {/* Buy Means */}
                                        {buyMeansShow === "false" && (
                                            <Form.Group className="mb-3" controlId="formBasicBuyMeans">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual o principal uso que você daria a uma bicicleta elétrica?</Form.Label>
                                                <Form.Check
                                                    label="Meio de locomoção"
                                                    name="buyMeans"
                                                    id="transport"
                                                    type="radio"
                                                    value="1"
                                                    checked={buyMeans === "1"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Prática de esporte"
                                                    name="buyMeans"
                                                    id="sports"
                                                    type="radio"
                                                    value="2"
                                                    checked={buyMeans === "2"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Lazer"
                                                    name="buyMeans"
                                                    id="fun"
                                                    type="radio"
                                                    value="3"
                                                    checked={buyMeans === "3"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Outro"
                                                    name="buyMeans"
                                                    id="other"
                                                    type="radio"
                                                    value="4"
                                                    checked={buyMeans === "4"}
                                                    onChange={(event) => handleChange(event, 'buyMeans')}
                                                    className="checkbox-with-margin"
                                                />
                                                {buyMeans.includes("4") && (
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Outro:"
                                                        className="floatingClass"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            value={otherBuyMeans}
                                                            onChange={(event) => handleChange(event, 'otherBuyMeans')}
                                                            className='formsInside form-control'
                                                        />
                                                    </FloatingLabel>
                                                )}
                                            </Form.Group>
                                        )
                                        }
                                        {/* Means */}
                                        {meansShow === "true" && (
                                            <Form.Group className="mb-3" controlId="formBasicBuyMeans">
                                                <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual o principal uso de uma bicicleta elétrica para você?</Form.Label>
                                                <Form.Check
                                                    label="Meio de locomoção"
                                                    name="Means"
                                                    id="transport1"
                                                    type="radio"
                                                    value="1"
                                                    checked={means === "1"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Prática de esporte"
                                                    name="Means"
                                                    id="sports1"
                                                    type="radio"
                                                    value="2"
                                                    checked={means === "2"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Lazer"
                                                    name="Means"
                                                    id="fun1"
                                                    type="radio"
                                                    value="3"
                                                    checked={means === "3"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                <Form.Check
                                                    label="Outro"
                                                    name="Means"
                                                    id="other1"
                                                    type="radio"
                                                    value="4"
                                                    checked={means === "4"}
                                                    onChange={(event) => handleChange(event, 'means')}
                                                    className="checkbox-with-margin"
                                                />
                                                {means.includes("4") && (
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Outro:"
                                                        className="floatingClass"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            value={otherMeans}
                                                            onChange={(event) => handleChange(event, 'otherMeans')}
                                                            className='formsInside form-control'
                                                        />
                                                    </FloatingLabel>
                                                )}
                                            </Form.Group>)
                                        }
                                        {/* Important Resources */}
                                        <Form.Group className="mb-3" controlId="formBasicImportantResources">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Que tipo de recursos você considera essenciais em uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Autonomia da bateria"
                                                name="importantResources"
                                                id="battery"
                                                type="checkbox"
                                                value="1"
                                                checked={resources.includes("1")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            <Form.Check
                                                label="Velocidade máxima"
                                                name="importantResources"
                                                id="maxvelocity"
                                                type="checkbox"
                                                value="2"
                                                checked={resources.includes("2")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Frenagem regenerativa"
                                                name="importantResources"
                                                id="breaking"
                                                type="checkbox"
                                                value="3"
                                                checked={resources.includes("3")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Pedal Assistido"
                                                name="importantResources"
                                                id="pedal"
                                                type="checkbox"
                                                value="4"
                                                checked={resources.includes("4")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Display informativo"
                                                name="importantResources"
                                                id="display"
                                                type="checkbox"
                                                value="5"
                                                checked={resources.includes("5")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />
                                            {<Form.Check
                                                label="Resiliência para qualquer tipo de terreno"
                                                name="importantResources"
                                                id="terrain"
                                                type="checkbox"
                                                value="6"
                                                checked={resources.includes("6")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {<Form.Check
                                                label="Encontrar a bicicleta pelo GPS"
                                                name="importantResources"
                                                id="GPS"
                                                type="checkbox"
                                                value="7"
                                                checked={resources.includes("7")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {<Form.Check
                                                label="Outros"
                                                name="importantResources"
                                                id="other10"
                                                type="checkbox"
                                                value="8"
                                                checked={resources.includes("8")}
                                                onChange={(event) => handleChange(event, 'resources')}
                                                className="checkbox-with-margin"
                                            />}
                                            {resources.includes("8") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherResource}
                                                        onChange={(event) => handleChange(event, 'otherResources')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}

                                        </Form.Group>
                                        {/* Kit Type */}
                                        <Form.Group className="mb-3" controlId="formBasicKitType">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Qual opção melhor se encaixa à sua necessidade/realidade?</Form.Label>
                                            <Form.Check
                                                label="Kit para conversão de uma bicicleta comum"
                                                name="kitType"
                                                type="radio"
                                                id="justKit"
                                                value="1"
                                                checked={kitType === "1"}
                                                onChange={(event) => handleChange(event, 'kitType')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="E-bike completa"
                                                name="kitType"
                                                type="radio"
                                                id="full"
                                                value="2"
                                                checked={kitType === "2"}
                                                onChange={(event) => handleChange(event, 'kitType')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>

                                        {/* Percepção de Valor */}

                                        {/* Important Thing */}
                                        <Form.Group className="mb-3" controlId="formBasicImportantThing">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>O que você considera mais importante ao avaliar o custo de uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Qualidade e durabilidade do produto"
                                                name="importantThing"
                                                id="quality3"
                                                type="checkbox"
                                                value="1"
                                                checked={importantThing.includes("1")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Recursos e tecnologia embarcada"
                                                name="importantThing"
                                                id="tecnology3"
                                                type="checkbox"
                                                value="2"
                                                checked={importantThing.includes("2")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Marca reconhecida no mercado"
                                                name="importantThing"
                                                id="brand3"
                                                type="checkbox"
                                                value="3"
                                                checked={importantThing.includes("3")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Preço mais acessível possível"
                                                name="importantThing"
                                                id="price3"
                                                type="checkbox"
                                                value="4"
                                                checked={importantThing.includes("4")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Outros"
                                                name="importantThing"
                                                id="other3"
                                                type="checkbox"
                                                value="5"
                                                checked={importantThing.includes("5")}
                                                onChange={(event) => handleChange(event, 'importantThing')}
                                                className="checkbox-with-margin"
                                            />
                                            {importantThing.includes("5") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherImportantThing}
                                                        onChange={(event) => handleChange(event, 'otherImportantThing')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}
                                        </Form.Group>
                                        {/* Value Comparison */}
                                        <Form.Group className="mb-3" controlId="formBasicValueComparison">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Em comparação com outros meios de transporte (ex: carro, transporte público, bicicleta convencional), você acredita que o investimento em uma bicicleta elétrica é:</Form.Label>
                                            <Form.Check
                                                label="Mais vantajoso financeiramente a longo prazo"
                                                name="valueComparison"
                                                id="longWay4"
                                                type="radio"
                                                value="1"
                                                checked={valueComparison === "1"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Comparável em termos de custo e benefício"
                                                name="valueComparison"
                                                id="comparable4"
                                                type="radio"
                                                value="2"
                                                checked={valueComparison === "2"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Mais caro, mas justificado pelos benefícios oferecidos"
                                                name="valueComparison"
                                                id="expensive4"
                                                type="radio"
                                                value="3"
                                                checked={valueComparison === "3"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não tenho certeza"
                                                name="valueComparison"
                                                id="notsure4"
                                                type="radio"
                                                value="4"
                                                checked={valueComparison === "4"}
                                                onChange={(event) => handleChange(event, 'valueComparison')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>
                                        {/* Influence Factors */}
                                        <Form.Group className="mb-3" controlId="formBasicInfluence Factors">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Que fatores você considera ao decidir o quanto está disposto a investir em uma bicicleta elétrica?</Form.Label>
                                            <Form.Check
                                                label="Avaliações online e recomendações de especialistas"
                                                name="influenceFactors"
                                                id="online5"
                                                type="checkbox"
                                                value="1"
                                                checked={influenceFactors.includes("1")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin" // Adicionando uma classe CSS personalizada
                                            />
                                            {<Form.Check
                                                label="Características específicas do produto (ex: autonomia da bateria, design etc.)"
                                                name="influenceFactors"
                                                id="specified5"
                                                type="checkbox"
                                                value="2"
                                                checked={influenceFactors.includes("2")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />}
                                            <Form.Check
                                                label="Preço em relação aos recursos oferecidos"
                                                name="influenceFactors"
                                                id="price5"
                                                type="checkbox"
                                                value="3"
                                                checked={influenceFactors.includes("3")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Outros"
                                                name="influenceFactors"
                                                id="other5"
                                                type="checkbox"
                                                value="4"
                                                checked={influenceFactors.includes("4")}
                                                onChange={(event) => handleChange(event, 'influenceFactors')}
                                                className="checkbox-with-margin"
                                            />
                                            {influenceFactors.includes("4") && (
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Outros:"
                                                    className="floatingClass"
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        value={otherInfluenceFactors}
                                                        onChange={(event) => handleChange(event, 'otherInfluenceFactors')}
                                                        className='formsInside form-control'
                                                    />
                                                </FloatingLabel>
                                            )}
                                        </Form.Group>
                                        {/* Spent Range */}
                                        <Form.Group className="mb-3" controlId="formBasicSpentRange">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Quanto você estaria disposto a investir em uma bicicleta elétrica de alta qualidade?</Form.Label>
                                            <br></br>
                                            <div>
                                                <h3 style={{ fontSize: '18px', fontFamily: 'Georama' }}>DE R$<b>{formatarNumeroComSeparadores(sliderValue[0])}</b> ATÉ R$<b>{formatarNumeroComSeparadores(sliderValue[1])}</b></h3>
                                            </div>
                                            <Box>
                                                <br></br>
                                                <Slider
                                                    sx={{
                                                        color: '#32a283',
                                                        '& .MuiSlider-thumb': {
                                                            height: 27,
                                                            width: 27,
                                                            backgroundColor: '#fff',
                                                            border: '1px solid currentColor',
                                                            '&:hover': {
                                                                boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                                                            },
                                                            '& .airbnb-bar': {
                                                                height: 9,
                                                                width: 1,
                                                                backgroundColor: 'currentColor',
                                                                marginLeft: 1,
                                                                marginRight: 1,
                                                            },
                                                        },
                                                        '& .MuiSlider-track': {
                                                            height: 3,
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            color: '#e65025',
                                                            opacity: 0.5,
                                                            height: 3,
                                                        },
                                                    }}

                                                    value={sliderValue}
                                                    onChange={handleChange1}
                                                    valueLabelDisplay="auto"
                                                    min={2000}
                                                    max={18000}
                                                    step={500}
                                                    /*                                                     getAriaValueText={valuetext} */
                                                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                                />
                                            </Box>

                                        </Form.Group>


                                        {/* Aditional Comments */}
                                        <Form.Group className="mb-3" controlId="formBasicAditionalComment">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Caso tenha algum comentário adicional sobre bicicletas elétricas ou sobre esta pesquisa, por favor, sinta-se à vontade para compartilhá-lo:</Form.Label>
                                            <Form.Control
                                                className='formsInside'
                                                as="textarea"
                                                placeholder=" Escreva aqui :)"
                                                value={comments}
                                                onChange={(event) => handleChange(event, 'comments')} />
                                        </Form.Group>

                                        {/* Subscription */}
                                        <Form.Group className="mb-3" controlId="formBasicSubscription">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você gostaria de receber informações sobre promoções, novos modelos ou eventos relacionados a bicicletas elétricas?</Form.Label>
                                            <Form.Check
                                                label="Sim"
                                                name="subscription"
                                                id="yes6"
                                                type="radio"
                                                value={true}
                                                onChange={(event) => handleChange(event, 'subscription')}
                                                className="checkbox-with-margin"
                                            />
                                            <Form.Check
                                                label="Não"
                                                name="subscription"
                                                id="no6"
                                                type="radio"
                                                value={false}
                                                onChange={(event) => handleChange(event, 'subscription')}
                                                className="checkbox-with-margin"
                                            />
                                        </Form.Group>

                                        {/* Aceite dos termos */}

                                        <Form.Group className="mb-3" controlId="formBasicTerms">
                                            <Form.Label className='blackFont colAlignLeft' style={{ fontSize: '18px', fontFamily: 'Georama' }}>Aceite os Termos e Condições deste desconto para finalizar:</Form.Label>
                                            <Form.Check
                                                label={
                                                    <span>
                                                        Aceito os <a href="/offerTerms" target="_blank" rel="noopener noreferrer" style={{ color: '#156da8' }}>Termos e Condições.</a>
                                                    </span>

                                                }
                                                className='switch-with-margin'
                                                name='Terms'
                                                id='terms1'
                                                type='switch'
                                                value={true}
                                                onChange={(event) => handleChange(event, 'terms')}
                                            />
                                        </Form.Group>



                                        <br></br>
                                        {/* Botão Enviar*/}
                                        <Button className='sendButton' onClick={generateUniqueId} disabled={activatedButton} type="submit" style={{ fontFamily: 'Georama' }}>ENVIAR</Button>{' '}
                                        <br></br>
                                    </Form>
                                )}
                            </Row>
                            <br></br>
                        </Col>
                    </Container>
                </div>
            ) : (
                /* Tela de sucesso */
                <div className='getOfferDIV'>
                    <Col>
                        {/* sucessScreen */}
                        <Container fluid>
                            {isMobile && (
                                <div className="formFilledDIVMobile">
                                    <Col>
                                        <h1 className='blackFont' style={{ fontSize: '40px', fontFamily: 'Georama' }}>Parabéns, {firstName}!</h1>
                                        <br></br>
                                        <div className='allDoneAnimation'>
                                            <Lottie className='allDoneAnimation1' loop="false" animationData={allDoneIcon} />
                                        </div>
                                        <br></br>
                                        <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você acaba de receber um cupom de 10% de desconto na compra de sua e-bike Primax.<br></br><br></br>Resgate seu cupom abaixo.</h4>
                                        <br></br>

                                        {/* Botão Resgatar */}
                                        <Button className='sendButton' onClick={ticketButton} disabled={rescueButton} type="submit" style={{ fontFamily: 'Georama' }}>RESGATAR</Button>{' '}
                                        <br></br>
                                        <br></br>
                                        {/* Cupom */}
                                        <Row>
                                            {ticketShow === true && (
                                                <div className='ticketAnimation'>
                                                    <Lottie className='ticketAnimation1' loop="false" animationData={ticket} />
                                                    <div className='ticketNumber'>
                                                        <h5 ref={textToCopyRef} className='ticketId' style={{ fontSize: '14px', fontFamily: 'Georama' }}>{formId}</h5>
                                                        <div className='copyBackgroundColor'>
                                                            <img onClick={copyTextToClipboard} src={copyIcon} alt='' style={{ width: '1.6rem' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }

                                        </Row>

                                    </Col>

                                </div>

                            )}
                            {isHD && (
                                <div className="formFilledDIVHD">
                                    <Col>
                                        <h1 className='blackFont' style={{ fontSize: '40px', fontFamily: 'Georama' }}>Parabéns, {firstName}!</h1>
                                        <br></br>
                                        <div className='allDoneAnimation'>
                                            <Lottie className='allDoneAnimation1' loop="false" animationData={allDoneIcon} />
                                        </div>
                                        <br></br>
                                        <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você acaba de receber um cupom de 10% de desconto na compra de sua e-bike Primax.<br></br><br></br>Resgate seu cupom abaixo.</h4>
                                        <br></br>

                                        {/* Botão Resgatar */}
                                        <Button className='sendButton' onClick={ticketButton} disabled={rescueButton} type="submit" style={{ fontFamily: 'Georama' }}>RESGATAR</Button>{' '}
                                        <br></br>
                                        <br></br>
                                        {/* Cupom */}
                                        <Row>
                                            {ticketShow === true && (
                                                <div className='ticketAnimation'>
                                                    <Lottie className='ticketAnimation1' loop="false" animationData={ticket} />
                                                    <div className='ticketNumber'>
                                                        <h5 ref={textToCopyRef} className='ticketId' style={{ fontSize: '14px', fontFamily: 'Georama' }}>{formId}</h5>
                                                        <div className='copyBackgroundColor'>
                                                            <img onClick={copyTextToClipboard} src={copyIcon} alt='' style={{ width: '1.6rem' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }

                                        </Row>

                                    </Col>

                                </div>

                            )}
                            {isFullHD && (
                                <div className="formFilledDIVHD">
                                    <Col>
                                        <h1 className='blackFont' style={{ fontSize: '40px', fontFamily: 'Georama' }}>Parabéns, {firstName}!</h1>
                                        <br></br>
                                        <div className='allDoneAnimation'>
                                            <Lottie className='allDoneAnimation1' loop="false" animationData={allDoneIcon} />
                                        </div>
                                        <br></br>
                                        <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Você acaba de receber um cupom de 10% de desconto na compra de sua e-bike Primax.<br></br><br></br>Resgate seu cupom abaixo.</h4>
                                        <br></br>

                                        {/* Botão Resgatar */}
                                        <Button className='sendButton' onClick={ticketButton} disabled={rescueButton} type="submit" style={{ fontFamily: 'Georama' }}>RESGATAR</Button>{' '}
                                        <br></br>
                                        <br></br>
                                        {/* Cupom */}
                                        <Row>
                                            {ticketShow === true && (
                                                <div className='ticketAnimation'>
                                                    <Lottie className='ticketAnimation1' loop="false" animationData={ticket} />
                                                    <div className='ticketNumber'>
                                                        <h5 ref={textToCopyRef} className='ticketId' style={{ fontSize: '14px', fontFamily: 'Georama' }}>{formId}</h5>
                                                        <div className='copyBackgroundColor'>
                                                            <img onClick={copyTextToClipboard} src={copyIcon} alt='' style={{ width: '1.6rem' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }

                                        </Row>

                                    </Col>

                                </div>

                            )}
                        </Container>
                        {/* footer */}
                        {isMobile && (
                            <Row className='bg-color-foot'>
                                <Col className="d-flex align-items-center justify-content-center">
                                    <Link to={"/home"}>
                                        <img className='logo-footer' src={fullLogoWhite} alt="Logotipo" width={150} />
                                    </Link>
                                </Col>
                                <Col className="d-flex align-items-center justify-content-center">
                                    <Link to={"/home"}>
                                        <div className="text-center">
                                            <Lottie className='forwardAnimation' animationData={forward} />
                                            <h4 style={{ fontSize: '16px', fontFamily: 'Georama', color: '#c1c1c1' }}>Visite nosso site</h4>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        )}
                        {isHD && (
                            <Row className='bg-color-footHD'>
                                <Col className="d-flex align-items-center justify-content-center">
                                    <Link to={"/home"}>
                                        <img className='logo-footer' src={fullLogoWhite} alt="Logotipo" width={150} />
                                    </Link>
                                </Col>
                                <Col className="d-flex align-items-center justify-content-center">
                                    <Link to={"/home"}>
                                        <div className="text-center">
                                            <Lottie className='forwardAnimation' animationData={forward} />
                                            <h4 style={{ fontSize: '16px', fontFamily: 'Georama', color: '#c1c1c1' }}>Visite nosso site</h4>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        )}
                        {isFullHD && (
                            <Row className='bg-color-footHD'>
                                <Col className="d-flex align-items-center justify-content-center">
                                    <Link to={"/home"}>
                                        <img className='logo-footer' src={fullLogoWhite} alt="Logotipo" width={150} />
                                    </Link>
                                </Col>
                                <Col className="d-flex align-items-center justify-content-center">
                                    <Link to={"/home"}>
                                        <div className="text-center">
                                            <Lottie className='forwardAnimation' animationData={forward} />
                                            <h4 style={{ fontSize: '16px', fontFamily: 'Georama', color: '#c1c1c1' }}>Visite nosso site</h4>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </div>
            )
            }
        </div >
    );
}