import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import logoIconGreen from '../../assets/svg/logoVerde.svg'
import GoogleFontLoader from 'react-google-font-loader';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';






export default function PageTerms() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isHD = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1400px)' });
    const isFullHD = useMediaQuery({ query: '(min-width: 1400px)' });

    function handleCloseWindow() {
        window.close();
    }

    return (
        <div>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />
            <Container className="mt-5">
                <Row className="justify-content-center">
                    {isFullHD && (
                        <Col xs={12} md={10} lg={8}>
                            <Row className="mb-3">
                                <Col xs={2} style={{ verticalAlign: "middle", display: "flex", paddingBottom: "20px" }}>
                                    <div className='textGetInTouch'>
                                        <img
                                            src={logoIconGreen}
                                            alt="Em breve"
                                            width={70}
                                        />
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <h2 className="text-center" style={{ fontSize: '32px', fontFamily: 'Georama' }}>Termos e Condições de Pesquisa sobre Perfis de Compradores de Bicicletas Elétricas.</h2>
                                    <hr className="line" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="terms-content">
                                        {/* Conteúdo dos termos e condições */}
                                        <p >
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Objetivo da Pesquisa</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Esta pesquisa tem como objetivo definir o perfil dos usuários e potenciais compradores de bicicletas elétricas, visando entender suas preferências, necessidades e comportamentos de consumo.</h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Desconto de 10% nas Primeiras Unidades de Venda</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Ao preencher o formulário de pesquisa, o participante terá direito a um desconto de 10% nas primeiras unidades de venda da bicicleta elétrica. Este desconto será aplicado enquanto houver estoque disponível.
                                                A aplicação do desconto está sujeita à disponibilidade de estoque, podendo ocorrer de o participante não usufruir do desconto caso as unidades disponíveis se esgotem.</h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Coleta de Dados</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Ao preencher o formulário de pesquisa, o participante concorda em fornecer informações precisas e verdadeiras.
                                                Os dados coletados incluirão nome, e-mail, telefone, CPF, gostos, preferências e outras informações relevantes para a pesquisa.
                                                Todas as informações coletadas serão tratadas conforme garantido pela Lei Geral de Proteção de Dados (LGPD).</h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Segurança dos Dados</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Os dados fornecidos pelos participantes serão tratados com confidencialidade e utilizados exclusivamente para os fins da pesquisa.
                                                Medidas de segurança serão adotadas para proteger os dados contra acesso não autorizado, uso indevido, alteração ou divulgação.</h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>LGPD - Lei Geral de Proteção de Dados</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Este estudo está em conformidade com a Lei Geral de Proteção de Dados (LGPD), respeitando os direitos dos participantes quanto à privacidade e proteção de dados pessoais.
                                                Os participantes têm o direito de acessar, corrigir, atualizar ou solicitar a exclusão de seus dados pessoais a qualquer momento por meio do contato: <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a></h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Uso dos Dados</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Os dados coletados serão utilizados exclusivamente para fins de análise e pesquisa de mercado.
                                                Os resultados da pesquisa poderão ser divulgados de forma agregada e anonimizada, garantindo a privacidade dos participantes.</h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Consentimento</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Ao participar da pesquisa, o usuário está consentindo com os termos e condições aqui descritos.</h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Contato</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Em caso de dúvidas sobre os termos e condições da pesquisa, os participantes podem entrar em contato conosco por meio do e-mail: <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a></h5>
                                            <h4 style={{ fontSize: '26px', fontFamily: 'Georama' }}>Aceitação dos Termos</h4>
                                            <h5 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Ao preencher e enviar o formulário de pesquisa, o participante confirma que leu, compreendeu e concorda com os termos e condições estabelecidos nesta página.</h5>
                                        </p>
                                        {/* Adicione mais parágrafos conforme necessário */}
                                    </div>
                                    <div className='colAlignCenter'>
                                        <br></br>
                                        {/* Botão Fechar Página*/}
                                        <Button className='sendButton' onClick={handleCloseWindow} type="submit" style={{ fontFamily: 'Georama', width: '200px' }}>ACEITO OS TERMOS</Button>{' '}
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )
                    }
                    {isHD && (
                        <Col xs={12} md={10} lg={8}>
                            <Row className="mb-3">
                                <Col xs={2} style={{ verticalAlign: "middle", display: "flex", paddingBottom: "20px" }}>
                                    <div className='textGetInTouch'>
                                        <img
                                            src={logoIconGreen}
                                            alt="Em breve"
                                            width={60}
                                        />
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <h2 className="text-center" style={{ fontSize: '24px', fontFamily: 'Georama' }}>Termos e Condições de Pesquisa sobre Perfis de Compradores de Bicicletas Elétricas.</h2>
                                    <hr className="line" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="terms-content">
                                        {/* Conteúdo dos termos e condições */}
                                        <p >
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Objetivo da Pesquisa</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Esta pesquisa tem como objetivo definir o perfil dos usuários e potenciais compradores de bicicletas elétricas, visando entender suas preferências, necessidades e comportamentos de consumo.</h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Desconto de 10% nas Primeiras Unidades de Venda</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Ao preencher o formulário de pesquisa, o participante terá direito a um desconto de 10% nas primeiras unidades de venda da bicicleta elétrica. Este desconto será aplicado enquanto houver estoque disponível.
                                                A aplicação do desconto está sujeita à disponibilidade de estoque, podendo ocorrer de o participante não usufruir do desconto caso as unidades disponíveis se esgotem.</h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Coleta de Dados</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Ao preencher o formulário de pesquisa, o participante concorda em fornecer informações precisas e verdadeiras.
                                                Os dados coletados incluirão nome, e-mail, telefone, CPF, gostos, preferências e outras informações relevantes para a pesquisa.
                                                Todas as informações coletadas serão tratadas conforme garantido pela Lei Geral de Proteção de Dados (LGPD).</h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Segurança dos Dados</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Os dados fornecidos pelos participantes serão tratados com confidencialidade e utilizados exclusivamente para os fins da pesquisa.
                                                Medidas de segurança serão adotadas para proteger os dados contra acesso não autorizado, uso indevido, alteração ou divulgação.</h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>LGPD - Lei Geral de Proteção de Dados</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Este estudo está em conformidade com a Lei Geral de Proteção de Dados (LGPD), respeitando os direitos dos participantes quanto à privacidade e proteção de dados pessoais.
                                                Os participantes têm o direito de acessar, corrigir, atualizar ou solicitar a exclusão de seus dados pessoais a qualquer momento por meio do contato: <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a></h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Uso dos Dados</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Os dados coletados serão utilizados exclusivamente para fins de análise e pesquisa de mercado.
                                                Os resultados da pesquisa poderão ser divulgados de forma agregada e anonimizada, garantindo a privacidade dos participantes.</h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Consentimento</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Ao participar da pesquisa, o usuário está consentindo com os termos e condições aqui descritos.</h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Contato</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Em caso de dúvidas sobre os termos e condições da pesquisa, os participantes podem entrar em contato conosco por meio do e-mail: <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a></h5>
                                            <h4 style={{ fontSize: '20px', fontFamily: 'Georama' }}>Aceitação dos Termos</h4>
                                            <h5 style={{ fontSize: '16px', fontFamily: 'Georama' }}>Ao preencher e enviar o formulário de pesquisa, o participante confirma que leu, compreendeu e concorda com os termos e condições estabelecidos nesta página.</h5>
                                        </p>
                                        {/* Adicione mais parágrafos conforme necessário */}
                                    </div>
                                    <div className='colAlignCenter'>
                                        <br></br>
                                        {/* Botão Fechar Página*/}
                                        <Button className='sendButton' onClick={handleCloseWindow} type="submit" style={{ fontFamily: 'Georama', width: '200px' }}>ACEITO OS TERMOS</Button>{' '}
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )
                    }
                    {isMobile && (
                        <Col xs={12} md={10} lg={8}>
                            <Row className="mb-3">
                                <Col xs={2} style={{ verticalAlign: "middle", display: "flex", paddingRight: "10px", paddingBottom: "20px" }}>
                                    <div className='textGetInTouch'>
                                        <img
                                            src={logoIconGreen}
                                            alt="Em breve"
                                            width={50}
                                        />
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <h2 className="text-center" style={{ fontSize: '22px', fontFamily: 'Georama' }}>Termos e Condições de Pesquisa sobre Perfis de Compradores de Bicicletas Elétricas.</h2>
                                    <hr className="line" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="terms-content">
                                        {/* Conteúdo dos termos e condições */}
                                        <p >
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Objetivo da Pesquisa</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Esta pesquisa tem como objetivo definir o perfil dos usuários e potenciais compradores de bicicletas elétricas, visando entender suas preferências, necessidades e comportamentos de consumo.</h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Desconto de 10% nas Primeiras Unidades de Venda</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Ao preencher o formulário de pesquisa, o participante terá direito a um desconto de 10% nas primeiras unidades de venda da bicicleta elétrica. Este desconto será aplicado enquanto houver estoque disponível.
                                                A aplicação do desconto está sujeita à disponibilidade de estoque, podendo ocorrer de o participante não usufruir do desconto caso as unidades disponíveis se esgotem.</h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Coleta de Dados</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Ao preencher o formulário de pesquisa, o participante concorda em fornecer informações precisas e verdadeiras.
                                                Os dados coletados incluirão nome, e-mail, telefone, CPF, gostos, preferências e outras informações relevantes para a pesquisa.
                                                Todas as informações coletadas serão tratadas conforme garantido pela Lei Geral de Proteção de Dados (LGPD).</h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Segurança dos Dados</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Os dados fornecidos pelos participantes serão tratados com confidencialidade e utilizados exclusivamente para os fins da pesquisa.
                                                Medidas de segurança serão adotadas para proteger os dados contra acesso não autorizado, uso indevido, alteração ou divulgação.</h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>LGPD - Lei Geral de Proteção de Dados</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Este estudo está em conformidade com a Lei Geral de Proteção de Dados (LGPD), respeitando os direitos dos participantes quanto à privacidade e proteção de dados pessoais.
                                                Os participantes têm o direito de acessar, corrigir, atualizar ou solicitar a exclusão de seus dados pessoais a qualquer momento por meio do contato: <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a></h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Uso dos Dados</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Os dados coletados serão utilizados exclusivamente para fins de análise e pesquisa de mercado.
                                                Os resultados da pesquisa poderão ser divulgados de forma agregada e anonimizada, garantindo a privacidade dos participantes.</h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Consentimento</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Ao participar da pesquisa, o usuário está consentindo com os termos e condições aqui descritos.</h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Contato</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Em caso de dúvidas sobre os termos e condições da pesquisa, os participantes podem entrar em contato conosco por meio do e-mail: <a href="mailto:contato@primaxebikes.com" style={{ fontFamily: 'Georama' }}>contato@primaxebikes.com</a></h5>
                                            <h4 style={{ fontSize: '18px', fontFamily: 'Georama' }}>Aceitação dos Termos</h4>
                                            <h5 style={{ fontSize: '14px', fontFamily: 'Georama' }}>Ao preencher e enviar o formulário de pesquisa, o participante confirma que leu, compreendeu e concorda com os termos e condições estabelecidos nesta página.</h5>
                                        </p>
                                        {/* Adicione mais parágrafos conforme necessário */}
                                    </div>
                                    <div className='colAlignCenter'>
                                        <br></br>
                                        {/* Botão Fechar Página*/}
                                        <Button className='sendButton' onClick={handleCloseWindow} type="submit" style={{ fontFamily: 'Georama', width: '200px' }}>ACEITO OS TERMOS</Button>{' '}
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )
                    }
                </Row>
            </Container>

        </div>
    )
}