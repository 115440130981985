import React from "react";
import "./styles.css";
import fullLogo from "../../assets/svg/fullLogo.svg"
import instagramIconBW from '../../assets/png/instagram_bw.png'
import facebookIconBW from '../../assets/png/facebookbw.png'
import emailIconBW from '../../assets/png/emailbw.png'
import youtubeIcon from '../../assets/png/youtube.png'
import { Container, Navbar, Nav } from "react-bootstrap";
/* import tiktokIcon1 from '../../assets/png/tiktok1.png' */
import linkedinIcon2 from '../../assets/png/linkedin2.png'
import { Tooltip } from 'react-tooltip'
import GoogleFontLoader from 'react-google-font-loader';


// Atenção: Não esquecer de alterar também no arquivo headerHome, que é utilizado
// na página Home

export default function Header() {

    return (
        <div>
            <GoogleFontLoader
                fonts={[
                    { font: 'Georama', weights: [200, 400, '400i', 700, '700i'] },
                    { font: 'Cairo Play', weights: [200, 400, '400i', 700, '700i'] },
                ]}
            />

            <header className="header fixed-top">
                <div className="socialMedia1 ">
                    <div className="socialBar container d-flex align-items-end justify-content-end pt-1">
                        <a href='https://www.instagram.com/primax.ebikes/'
                            data-tooltip-id="instagram-tooltip"
                            data-tooltip-content="Acesso nosso Instagram!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={instagramIconBW}
                                width={25}
                                alt="Logotipo instagram"
                                className="socialMediaSpace"
                            />
                            <Tooltip id="instagram-tooltip" className="tooltip" />
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=61551105877417'
                            data-tooltip-id="facebook-tooltip"
                            data-tooltip-content="Acesso nossa página no Facebook!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={facebookIconBW}
                                width={25}
                                alt="Logotipo facebook"
                                className="socialMediaSpace" />
                            <Tooltip id="facebook-tooltip" className="tooltip" />
                        </a>
                        <a href='https://www.youtube.com/@primaxebikes'
                            data-tooltip-id="youtube-tooltip"
                            data-tooltip-content="Curta nosso conteúdo no Youtube!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={youtubeIcon}
                                width={27}
                                alt="Logotipo youtube"
                                className="socialMediaSpace" />
                            <Tooltip id="youtube-tooltip" className="tooltip" />
                        </a>
                        <a href="https://www.linkedin.com/company/primax-ebikes"
                            data-tooltip-id="linkedin-tooltip"
                            data-tooltip-content="Acesso nossa página no LinkedIn!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={linkedinIcon2}
                                width={26}
                                alt="Logotipo linkedin"
                                className="socialMediaSpace" />
                            <Tooltip id="linkedin-tooltip" className="tooltip" />
                        </a>
{/*                         <a href="https://www.tiktok.com/@primax.ebikes?_t=8ilPI5ZuuHt&_r=1"
                            data-tooltip-id="tiktok-tooltip"
                            data-tooltip-content="Acesso nosso TikTok!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={tiktokIcon1}
                                width={27}
                                alt="Logotipo tiktok"
                                className="socialMediaSpace" />
                            <Tooltip id="tiktok-tooltip" className="tooltip" />
                        </a> */}
                        <a href="mailto:contato@primaxebikes.com"
                            data-tooltip-id="email-tooltip"
                            data-tooltip-content="Entre em contato conosco por e-mail!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={emailIconBW}
                                width={28}
                                alt="Logotipo email"
                                className="socialMediaSpace" />
                            <Tooltip id="email-tooltip" className="tooltip" />
                        </a>
                    </div>
                </div>
                <div>
                    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                        <Container>
                            <Navbar.Brand href="/home" >
                                <img
                                    src={fullLogo}
                                    width="150"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="React Bootstrap logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                </Nav>
                                <Nav>
                                    <Nav.Link href="/home" style={{
                                        fontFamily: 'Georama, sans-serif',
                                        fontWeight: 400,
                                        fontSize: 18,
                                        color: "#101010",
                                    }}
                                        onMouseOver={(e) => (e.target.style.color = '#e65025')} // Cor durante o hover
                                        onMouseOut={(e) => (e.target.style.color = '#101010')} // Restaura a cor padrão após o hover
                                    >INÍCIO</Nav.Link>
                                    <Nav.Link href="/about" style={{
                                        fontFamily: 'Georama, sans-serif', // Use a fonte diretamente
                                        fontWeight: 400,
                                        fontSize: 18,
                                        color: "#101010",
                                    }}
                                        onMouseOver={(e) => (e.target.style.color = '#e65025')} // Cor durante o hover
                                        onMouseOut={(e) => (e.target.style.color = '#101010')} // Restaura a cor padrão após o hover
                                    >SOBRE</Nav.Link>
                                    {/* <Nav.Link href="#services"  style={{
                                        fontFamily: 'Poppins, sans-serif', // Use a fonte diretamente
                                        fontWeight: 400,
                                        fontSize: 18,
                                        color: "#101010",
                                    }}
                                    onMouseOver={(e) => (e.target.style.color = '#e65025')} // Cor durante o hover
                                    onMouseOut={(e) => (e.target.style.color = '#101010')} // Restaura a cor padrão após o hover
                                    >Produto</Nav.Link> */}
                                    <Nav.Link href="/contact" style={{
                                        fontFamily: 'Georama, sans-serif', // Use a fonte diretamente
                                        fontWeight: 400,
                                        fontSize: 18,
                                        color: "#101010",
                                    }}
                                        onMouseOver={(e) => (e.target.style.color = '#e65025')} // Cor durante o hover
                                        onMouseOut={(e) => (e.target.style.color = '#101010')} // Restaura a cor padrão após o hover
                                    >CONTATO</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </header >
        </div>
    );
}
